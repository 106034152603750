import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class app_custom_ds_report_outbolsalesorders_headerService {

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    ) { 
  }

  public async get(inParams: { orderId: number }): 
  Promise<{ result: { Id?: number, AccountId?: number, BillingAddresId?: number, CreatedSysUser?: string, LookupCode?: string, Notes?: string, OwnerReference?: string, ProjectId?: number, VendorReference?: string, ShipmentOrderLookups?: { OrderId?: number, ShipmentId?: number, Shipment?: { Id?: number, ActualWarehouseId?: number, BillOfLading?: string, CarrierId?: number, LookupCode?: string, PalletCount?: number, SealId?: string, ShippedDate?: string, TrailerId?: string } }[], Project?: { Id?: number, OwnerId?: number, Owner?: { Id?: number, Name?: string } }, warehouse?: { Id?: number, Name?: string, WarehousesContactsLookup?: { WarehouseId?: number, ContactId?: number }[], whaddr?: { Id?: number, AddressId?: number, PrimaryFax?: string, PrimaryTelephone?: string, Address?: { Id?: number, City?: string, Country?: string, Line1?: string, Line2?: string, PostalCode?: string, State?: string } } }, carrier?: { Id?: number, Name?: string }, shipandpymtterm?: { Id?: number, BillOfLading?: string, LookupCode?: string, PalletCount?: number, SealId?: string, ShippedDate?: string, TrailerId?: string, PaymentTerm?: { Id?: number, Name?: string } }, ordaddr?: { Id?: number, AttentionOf?: string, City?: string, Country?: string, Line1?: string, Line2?: string, Orderid?: number, PostalCode?: string, State?: string }, projaddr?: { Id?: number, AddressId?: number, OwnerId?: number, Address?: { Id?: number, AttentionOf?: string, City?: string, Country?: string, Line1?: string, Line2?: string, PostalCode?: string, State?: string } } } }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.orderId)) {
      missingRequiredInParams.push('\'orderId\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/app/datasources/custom_ds_report_outbolsalesorders_header/get`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }

}
