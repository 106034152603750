import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class app_custom_ds_report_outbolsalesorders_linesService {

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    ) { 
  }

  public async get(inParams: { orderId: number }): 
  Promise<{ result: { Id?: number, ActualPackagedAmount?: number, NetWeight?: number, GrossWeight?: number, ActualTargetLicensePlateId?: number, MaterialId?: number, ActualPackagedPackId?: number, linedetails?: { Id?: number, ActualPackagedPackId?: number, ActualTargetLicensePlateId?: number, MaterialId?: number, ShipmentId?: number, ActualTargetLicensePlate?: { Id?: number, LookupCode?: string, ShipmentId?: number }, Material?: { Id?: number, Description?: string, LookupCode?: string, Name?: string }, ActualPackagedPack?: { Id?: number, DimensionUomId?: number, ShippingWeight?: number, Weight?: number, WeightUomId?: number, DimensionsUom?: { Id?: number, Short_name?: string }, WeightUom?: { Id?: number, Short_name?: string } }, IsPicked?: boolean } }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.orderId)) {
      missingRequiredInParams.push('\'orderId\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/app/datasources/custom_ds_report_outbolsalesorders_lines/get`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }
  public async getList(inParams: { orderId: number, $top?: number, $skip?: number }): 
  Promise<{ result: { Id?: number, ActualPackagedAmount?: number, NetWeight?: number, GrossWeight?: number, ActualTargetLicensePlateId?: number, MaterialId?: number, ActualPackagedPackId?: number, linedetails?: { Id?: number, ActualPackagedPackId?: number, ActualTargetLicensePlateId?: number, MaterialId?: number, ShipmentId?: number, ActualTargetLicensePlate?: { Id?: number, LookupCode?: string, ShipmentId?: number }, Material?: { Id?: number, Description?: string, LookupCode?: string, Name?: string }, ActualPackagedPack?: { Id?: number, DimensionUomId?: number, ShippingWeight?: number, Weight?: number, WeightUomId?: number, DimensionsUom?: { Id?: number, Short_name?: string }, WeightUom?: { Id?: number, Short_name?: string } }, IsPicked?: boolean } }[], totalCount: number }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.orderId)) {
      missingRequiredInParams.push('\'orderId\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/app/datasources/custom_ds_report_outbolsalesorders_lines/getList`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }
  public async getByKeys(inParams: { orderId: number, $keys: { ActualTargetLicensePlateId?: number, MaterialId?: number, ActualPackagedPackId?: number }[] }): 
  Promise<{ result: { Id?: number, ActualPackagedAmount?: number, NetWeight?: number, GrossWeight?: number, ActualTargetLicensePlateId?: number, MaterialId?: number, ActualPackagedPackId?: number, linedetails?: { Id?: number, ActualPackagedPackId?: number, ActualTargetLicensePlateId?: number, MaterialId?: number, ShipmentId?: number, ActualTargetLicensePlate?: { Id?: number, LookupCode?: string, ShipmentId?: number }, Material?: { Id?: number, Description?: string, LookupCode?: string, Name?: string }, ActualPackagedPack?: { Id?: number, DimensionUomId?: number, ShippingWeight?: number, Weight?: number, WeightUomId?: number, DimensionsUom?: { Id?: number, Short_name?: string }, WeightUom?: { Id?: number, Short_name?: string } }, IsPicked?: boolean } }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.orderId)) {
      missingRequiredInParams.push('\'orderId\'');
    }
    if (isNil(inParams.$keys)) {
      missingRequiredInParams.push('\'$keys\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/app/datasources/custom_ds_report_outbolsalesorders_lines/getByKeys`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }

}
