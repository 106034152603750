import { Inject, Injectable, Injector } from '@angular/core';

import { Document360_FlowService } from './Document360.flow.index';
import { Usersnap_FlowService } from './Usersnap.flow.index';
import { Utilities_FlowService } from './Utilities.flow.index';
import { Locations_FlowService } from './Locations.flow.index';
import { Lots_FlowService } from './Lots.flow.index';
import { PurchaseOrders_FlowService } from './PurchaseOrders.flow.index';
import { Materials_FlowService } from './Materials.flow.index';
import { FootPrintManager_FlowService } from './FootPrintManager.flow.index';


import { $frontendTypes } from './app.frontend.types'

@Injectable({ providedIn: 'root' })
export class app_FlowService {

  constructor(
    private injector: Injector
  ) {
    this.Document360 = this.injector.get(Document360_FlowService);
    this.Usersnap = this.injector.get(Usersnap_FlowService);
    this.Utilities = this.injector.get(Utilities_FlowService);
    this.Locations = this.injector.get(Locations_FlowService);
    this.Lots = this.injector.get(Lots_FlowService);
    this.PurchaseOrders = this.injector.get(PurchaseOrders_FlowService);
    this.Materials = this.injector.get(Materials_FlowService);
    this.FootPrintManager = this.injector.get(FootPrintManager_FlowService);
  }

    public Document360: Document360_FlowService;
    public Usersnap: Usersnap_FlowService;
    public Utilities: Utilities_FlowService;
    public Locations: Locations_FlowService;
    public Lots: Lots_FlowService;
    public PurchaseOrders: PurchaseOrders_FlowService;
    public Materials: Materials_FlowService;
    public FootPrintManager: FootPrintManager_FlowService;
  public app: app_FlowService = this;

  // injecting lazily in order to avoid circular dependencies
}
