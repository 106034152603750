import { Component, OnInit, Input, SimpleChanges, OnChanges, EventEmitter, Output, ViewChild, Inject, OnDestroy, forwardRef } from '@angular/core';
import { FormGroup, FormControl, Validators} from '@angular/forms';

import { DatexFormControl } from './models/datex-form-control';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles 
} from './models/control';
import { GridHeaderModel, GridCellModel, GridRowModel, CellStyles, GridContainerStyle, HeaderStyles } from './models/grid'
import { FieldModel } from './models/field'
import { ToolModel } from './models/tool';
import { Styles, ControlContainerStyles } from './models/style';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { isEqual, isNil, set } from 'lodash-es';
import { GridComponent, ELoadingStatus } from './components/grid.component';
import { BaseComponent } from './components/base.component';
import { WorkBook, read as readExcelFile, writeFile as writeExcelFile, utils as excelUtils } from 'xlsx';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { FootPrintManager_ShellService, EModalSize, EToasterType, EToasterPosition } from './FootPrintManager.shell.service';
import { FootPrintManager_OperationService } from './FootPrintManager.operation.service';
import { FootPrintManager_DatasourceService } from './FootPrintManager.datasource.index';
import { FootPrintManager_FlowService } from './FootPrintManager.flow.index';
import { FootPrintManager_ReportService } from './FootPrintManager.report.index';
import { FootPrintManager_LocalizationService } from './FootPrintManager.localization.service';
import { Language } from './localization.service';
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './FootPrintManager.frontend.types'
import { $frontendTypes as $types} from './FootPrintManager.frontend.types' 



interface IFootPrintManager_sales_order_line_kit_gridComponentEntity {
Id?: number, ComponentMaterialId?: number, ComponentPackagedAmount?: number, ComponentPackagedId?: number, MaterialId?: number, PackagingId?: number, ComponentMaterial?: { LookupCode?: string }}

interface IFootPrintManager_sales_order_line_kit_gridComponentInParams {
  material_id: number, packaging_id: number, project_id: number, order_id: number, line_number: number, line_status_id: number, packaged_amount: number, warehouse_id: number, shipment_id: number}


class FootPrintManager_sales_order_line_kit_gridComponentRowModel extends GridRowModel {
  grid: FootPrintManager_sales_order_line_kit_gridComponent;
  entity: IFootPrintManager_sales_order_line_kit_gridComponentEntity;



 // temporarily put required on all edit controls
  override formGroup = new FormGroup({
    required_quantity_edit: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
  });

  override cells = {
    component_material_id: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    component_packaging_id: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    quantity: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    required_quantity: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
      new NumberBoxModel(this.formGroup.controls['required_quantity_edit'] as DatexFormControl, null, false, '', '')
      ),
    available: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    substitute_materials: new GridCellModel(
      new CellStyles(['grid-table-cell-link','center'], null),
      new LabelModel(null, null)
,
null
      ),
  }



  constructor(
    private utils: UtilsService,
private settings: SettingsValuesService,
private shell: FootPrintManager_ShellService,
private datasources: FootPrintManager_DatasourceService,
private flows: FootPrintManager_FlowService,
private reports: FootPrintManager_ReportService,
private localization: FootPrintManager_LocalizationService,
private operations: FootPrintManager_OperationService,
private logger: CleanupLoggerService,
) {
    super();
    
    this.formGroup
      .controls['required_quantity_edit']
      .valueChanges
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_required_quantity_change();
      });
  }

  async $initializeExisting(grid: FootPrintManager_sales_order_line_kit_gridComponent, entity: IFootPrintManager_sales_order_line_kit_gridComponentEntity, propertyChangeCallback: (source: GridRowModel, property: string) => void = null) {
    this.$propertyChangeCallback = propertyChangeCallback;

    this.grid = grid;
    this.entity = entity;

    this.rowId = [this.entity.Id].join('-');

    await this.$dataLoaded();
  }

  async $initializeNew(grid: FootPrintManager_sales_order_line_kit_gridComponent, entity?: IFootPrintManager_sales_order_line_kit_gridComponentEntity, propertyChangeCallback: (source: GridRowModel, property: string) => void = null) {
    this.$propertyChangeCallback = propertyChangeCallback;

    this.grid = grid;
    this.isNew = true;

    if (entity) {
      this.entity = entity;
    }
    else {
      this.entity = {};
    }

    this.rowId = [this.entity.Id].join('-');
    this.$dataLoaded();
    // mark the whole form as dirty, since this is a new row 
    // and even if it is valid, it should have a changed state
    this.formGroup.markAsDirty();
  }

  private $unsubscribe$ = new Subject();
  override destroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }

  $init() {
  }

  async $dataLoad() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.utils;
    const $resultKey = this.entity.Id;
    const inParams = {
      $keys:[$resultKey],
      material_id:  $grid.inParams.material_id ,
      packaging_id:  $grid.inParams.packaging_id ,
      full_text_search:  $grid.fullTextSearch ,
    };
    const data = await this.datasources.FootPrintManager.ds_sales_order_line_kit_grid.getByKeys(inParams);
    this.entity = data.result[0];
    await this.$dataLoaded();
  }

  async $dataLoaded() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.utils;
    (this.cells.component_material_id.displayControl as TextModel).text = $row.entity.ComponentMaterial?.LookupCode;
    (this.cells.quantity.displayControl as TextModel).text = $row.entity.ComponentPackagedAmount?.toString();
    (this.cells.required_quantity.displayControl as TextModel).text = ($row.entity.ComponentPackagedAmount * $grid.inParams.packaged_amount).toString();
    (this.cells.required_quantity.editControl as NumberBoxModel).reset(($row.entity.ComponentPackagedAmount * $grid.inParams.packaged_amount));

    await this.on_row_data_loaded();
  }

  override async refresh() {
    if (this.grid.$hasMissingRequiredInParams) {
      return Promise.resolve(null);
    }
    await this.$dataLoad();
    // grid refresh skipSelf=true, skipParent=false, skipChildren=false  
    this.grid.$refreshEvent.emit();
  }

  override async save() {
    if (this.isNew) {
    } else {
    } 
  }

  override $cellClicked(cellId: string) {
    super.$cellClicked(cellId);
    switch(cellId) {
      case 'substitute_materials' : {
        this.on_substitute_materials_clicked();
        break;
      }
    }
  }

  //#region private flows
  on_save_existing_row(event = null) {
    return this.on_save_existing_rowInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_save_existing_rowInternal(
    $row: FootPrintManager_sales_order_line_kit_gridComponentRowModel,
  $grid: FootPrintManager_sales_order_line_kit_gridComponent, 
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
    this.logger.log('FootPrintManager', 'sales_order_line_kit_grid.on_save_existing_row');
  
  
  let payload: any = {};
  
  
  
  try {
  
  
  
    
  
  }
  catch (error) {
      await $shell.FootPrintManager.showErrorDetails('Save', 'Error on save.', error);
      //$shell.Materials.openErrorDialog('Error on save', error.message);
      throw error; // to prevent displayMode 
  }
  
  
  
  
  
  }
  on_substitute_materials_clicked(event = null) {
    return this.on_substitute_materials_clickedInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_substitute_materials_clickedInternal(
    $row: FootPrintManager_sales_order_line_kit_gridComponentRowModel,
  $grid: FootPrintManager_sales_order_line_kit_gridComponent, 
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  
  
  const component_material_id = $row.entity.ComponentMaterialId;
  const component_packaged_id = $row.entity.ComponentPackagedId;
  
  if ($utils.isAllDefined(component_material_id, component_packaged_id)) {
      const results = (await $shell.FootPrintManager.opensales_order_line_substitutions_gridDialog({
          material_id: component_material_id,
          packaging_id: component_packaged_id,
          warehouse_id: $grid.inParams.warehouse_id
      }, "flyout", EModalSize.Xlarge));
  
      if ($utils.isDefined(results)) {
  
          const material_id = results.material_id;
          const packaging_id = results.packaging_id;
  
          const material = (await $datasources.Materials.ds_get_material_by_materialId.get({materialId: material_id})).result;
          const lookup = material[0].LookupCode;
  
          const packaging = (await $datasources.Materials.ds_get_material_inventory_measurements_by_unitId.get({unitId: packaging_id})).result;
          const short_name = packaging[0].ShortName;
  
          const grid_lines = $grid.vars.lines;
  
          if ($utils.isDefined(grid_lines)) {
  
              for (let grid_line of grid_lines) {
  
                  if (grid_line.original_material_id === component_material_id && grid_line.original_packaging_id === component_packaged_id) {
                      grid_line.material_id = material_id;
                      grid_line.packaging_id = packaging_id;
                      $row.cells.component_material_id.displayControl.text = lookup;
                      $row.cells.component_packaging_id.displayControl.text = short_name;
                  }
              }
  
          }
  
  
      }
  
  
  }
  }
  on_row_data_loaded(event = null) {
    return this.on_row_data_loadedInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_row_data_loadedInternal(
    $row: FootPrintManager_sales_order_line_kit_gridComponentRowModel,
  $grid: FootPrintManager_sales_order_line_kit_gridComponent, 
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  // Initialize data
  const component_material_id = $row.entity.ComponentMaterialId;
  const component_material_packaging_id = $row.entity.ComponentPackagedId;
  
  if ($utils.isAllDefined(component_material_id, component_material_packaging_id)) {
  
      // Set the packaging name in the row as the expand does not exist in the grid datasource
      const packaging = (await $datasources.Materials.ds_get_material_inventory_measurements_by_unitId.get({
          unitId: component_material_packaging_id
      })).result;
  
      const short_name = packaging[0].ShortName;
      $row.cells.component_packaging_id.displayControl.text = `${short_name}`;
  
  
      const substitutions = (await $datasources.Materials.ds_get_substitutions_by_material_id_packaging_id.get({
          material_id: component_material_id,
          packaging_id: component_material_packaging_id
      })).result;
  
  
      if ($utils.isDefined(substitutions)) {
  
          let material_string = '';
  
          for (let substitute of substitutions) {
  
              const material = substitute.SubstitutionMaterialPackagingLookup?.Material?.LookupCode;
              if ($utils.isDefined(material)) {
                  material_string = ' ' + material + material_string;
              }
  
  
          }
  
          $row.cells.substitute_materials.displayControl.label = `${material_string}`
  
  
      } else {
  
          $row.cells.substitute_materials.displayControl.label = null;
      }
  
      // Set available cell
      let inventory_available = 0;
  
      const inventory = (await $flows.SalesOrders.get_sales_order_line_availability_flow({
          materialId: component_material_id,
          packagedId: component_material_packaging_id,
          warehouseId: $grid.inParams.warehouse_id
      }));
      if ($utils.isDefined(inventory)) {
          inventory_available = inventory.availableByMaterial;
  
          $row.cells.available.displayControl.text = inventory_available.toString();
  
      }
      else {
          $row.cells.available.displayControl.text = inventory_available.toString();
  
      }
  
  
      // Inventory availability line styling
      const required_quantity = ($row.entity.ComponentPackagedAmount * $grid.inParams.packaged_amount) ?? 0;
      const available = inventory_available
      if ($utils.isDefined(available)) {
  
          if (required_quantity > available) {
              // Signal red
              setColors("rgba(175, 49, 42)");
          }
      }
  
  }
  
  //**FUNCTIONS */
  function setColors(color: string) {
      $row.cells.available.styles.setStyle("background-color", color);
      $row.cells.required_quantity.styles.setStyle("background-color", color);
  
  
  }
  
  
  }
  on_required_quantity_change(event = null) {
    return this.on_required_quantity_changeInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_required_quantity_changeInternal(
    $row: FootPrintManager_sales_order_line_kit_gridComponentRowModel,
  $grid: FootPrintManager_sales_order_line_kit_gridComponent, 
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  
  if ($row.cells.required_quantity.editControl.isChanged) {
      
      const material_id = $row.entity.ComponentMaterialId;
      const packaging_id = $row.entity.ComponentPackagedId;
      const updated_required_amount = $row.cells.required_quantity.editControl.value ?? 0;
  
      const grid_lines = $grid.vars.lines;
  
      if ($utils.isDefined(grid_lines)) {
  
          for (let grid_line of grid_lines) {
  
              if (grid_line.original_material_id === material_id && grid_line.original_packaging_id === packaging_id) {
                  grid_line.packaged_amount = updated_required_amount;
                  $row.cells.required_quantity.displayControl.text = updated_required_amount.toString();
                  $row.cells.required_quantity.editControl.value = updated_required_amount;
              }
          }
  
      }
  
  }
  }
  //#endregion private flows

}


@Component({
  standalone: true,
  imports: [
    SharedModule,
  ],
  selector: 'FootPrintManager-sales_order_line_kit_grid',
  templateUrl: './FootPrintManager.sales_order_line_kit_grid.component.html'
})
export class FootPrintManager_sales_order_line_kit_gridComponent extends BaseComponent implements OnInit, OnChanges, OnDestroy {
  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  @Output()
  $refreshEvent = new EventEmitter();
  //#endregion Outputs

  entities: IFootPrintManager_sales_order_line_kit_gridComponentEntity[];

  pageSize = 10;
  pageSkip = 0;
  totalCount = 0;
  loadingStatus = ELoadingStatus.Loading;

canEdit: boolean = true;
// to stop add from flow
// canAdd = false; //hide add button, check on exitFromLastCell 
// bottomToolbar.addLine.hidden = true; //hide add button, no check on exitFromLastCell 
// bottomToolbar.addLine.control.readOnly = true; //disable add button, check on exitFromLastCell 
 
  
  containerStyles: GridContainerStyle = new GridContainerStyle('fitedWidth', ['compact','noscroll-table']);

  fullTextSearch: string;

  inParams: IFootPrintManager_sales_order_line_kit_gridComponentInParams = { material_id: null, packaging_id: null, project_id: null, order_id: null, line_number: null, line_status_id: null, packaged_amount: null, warehouse_id: null, shipment_id: null };


  //#region Variables
  vars: { lines?: { material_id?: number, packaging_id?: number, packaged_amount?: number, original_material_id?: number, original_packaging_id?: number }[] } = { };
  //#endregion
  //#region Events
  
  //#endregion

  headers = {
     component_material_id: new GridHeaderModel(new HeaderStyles(null, null), 'Component', false, false, null),       component_packaging_id: new GridHeaderModel(new HeaderStyles(null, null), 'Packaging', false, false, null),       quantity: new GridHeaderModel(new HeaderStyles(null, null), 'Quantity', false, false, null),       required_quantity: new GridHeaderModel(new HeaderStyles(null, null), 'Required quantity', false, false, null),       available: new GridHeaderModel(new HeaderStyles(null, null), 'Available', false, false, null),       substitute_materials: new GridHeaderModel(new HeaderStyles(['center'], null), 'Substitutions?', false, false, null),  
  };

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  rows: FootPrintManager_sales_order_line_kit_gridComponentRowModel[] = [];
  @ViewChild('$gridComponent', { read:  GridComponent}) $gridComponent: GridComponent;

  @Input('material_id') set $inParams_material_id(value: any) {
    this.inParams['material_id'] = value;
  }
  get $inParams_material_id(): any {
    return this.inParams['material_id'] ;
  }
  @Input('packaging_id') set $inParams_packaging_id(value: any) {
    this.inParams['packaging_id'] = value;
  }
  get $inParams_packaging_id(): any {
    return this.inParams['packaging_id'] ;
  }
  @Input('project_id') set $inParams_project_id(value: any) {
    this.inParams['project_id'] = value;
  }
  get $inParams_project_id(): any {
    return this.inParams['project_id'] ;
  }
  @Input('order_id') set $inParams_order_id(value: any) {
    this.inParams['order_id'] = value;
  }
  get $inParams_order_id(): any {
    return this.inParams['order_id'] ;
  }
  @Input('line_number') set $inParams_line_number(value: any) {
    this.inParams['line_number'] = value;
  }
  get $inParams_line_number(): any {
    return this.inParams['line_number'] ;
  }
  @Input('line_status_id') set $inParams_line_status_id(value: any) {
    this.inParams['line_status_id'] = value;
  }
  get $inParams_line_status_id(): any {
    return this.inParams['line_status_id'] ;
  }
  @Input('packaged_amount') set $inParams_packaged_amount(value: any) {
    this.inParams['packaged_amount'] = value;
  }
  get $inParams_packaged_amount(): any {
    return this.inParams['packaged_amount'] ;
  }
  @Input('warehouse_id') set $inParams_warehouse_id(value: any) {
    this.inParams['warehouse_id'] = value;
  }
  get $inParams_warehouse_id(): any {
    return this.inParams['warehouse_id'] ;
  }
  @Input('shipment_id') set $inParams_shipment_id(value: any) {
    this.inParams['shipment_id'] = value;
  }
  get $inParams_shipment_id(): any {
    return this.inParams['shipment_id'] ;
  }

  topToolbar = {
      create_kit_component_lines: new ToolModel(new ButtonModel('create_kit_component_lines', new ButtonStyles(null, null), false, 'Create kit component lines', 'ms-Icon ms-Icon--Add')
    ),
      separator1: new ToolModel(new SeparatorModel(new Styles(null, null))
    ),
      revert_changes: new ToolModel(new ButtonModel('revert_changes', new ButtonStyles(null, null), false, 'Revert changes', 'ms-Icon ms-Icon--Undo')
    )
  };

  bottomToolbar = {
  };



  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    private shell: FootPrintManager_ShellService,
    private datasources: FootPrintManager_DatasourceService,
    private flows: FootPrintManager_FlowService,
    private reports: FootPrintManager_ReportService,
    private localization: FootPrintManager_LocalizationService,
    private operations: FootPrintManager_OperationService,
    private logger: CleanupLoggerService,
    ) {
    super();
    this.title = 'Sales order line kit management';
    this.$subscribeFormControlValueChanges();
  }

  ngOnInit(): void {
    this.$checkRequiredInParams();
    if (!this.$hasMissingRequiredInParams) {
      this.$init();
    } else {
      this.$initEmpty();
    }
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$checkRequiredInParams();
      if(!this.$hasMissingRequiredInParams) {
        this.$init();
      } else {
        this.$initEmpty();
      }
    }
  }

  $missingRequiredInParams = [];
  get $hasMissingRequiredInParams(): boolean {
    return !!this.$missingRequiredInParams.length;
  }
  
  $checkRequiredInParams() {
    this.$missingRequiredInParams = [];
      if(isNil(this.inParams.material_id)) {
        this.$missingRequiredInParams.push('material_id');
      }
      if(isNil(this.inParams.packaging_id)) {
        this.$missingRequiredInParams.push('packaging_id');
      }
      if(isNil(this.inParams.project_id)) {
        this.$missingRequiredInParams.push('project_id');
      }
      if(isNil(this.inParams.order_id)) {
        this.$missingRequiredInParams.push('order_id');
      }
      if(isNil(this.inParams.line_number)) {
        this.$missingRequiredInParams.push('line_number');
      }
      if(isNil(this.inParams.line_status_id)) {
        this.$missingRequiredInParams.push('line_status_id');
      }
      if(isNil(this.inParams.packaged_amount)) {
        this.$missingRequiredInParams.push('packaged_amount');
      }
      if(isNil(this.inParams.warehouse_id)) {
        this.$missingRequiredInParams.push('warehouse_id');
      }
      if(isNil(this.inParams.shipment_id)) {
        this.$missingRequiredInParams.push('shipment_id');
      }
  }

  initialized = false;

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();

    this.clearRows();
  }

  async $init() {
    const $grid = this;
    const $utils = this.utils;


    this.initialized = true;

    this.pageSkip = 0;
    this.refresh(true, true, null);
  }

  protected override $initEmpty() {
    this.clearRows();
  }

  private $subscribeFormControlValueChanges() {

  }

  reload() {
    this.pageSkip = 0;
    this.refresh();
  }

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    if (this.$hasMissingRequiredInParams) {
      return Promise.resolve(null);
    }
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = this.$dataLoad();

    // children
    if (skipChildren === false) {
    }

    return result;
  }

  $rowPropertyChangeCallback (source: GridRowModel, property: string): void {
    if (property === 'selected') {
      this.$gridComponent.updateAllSelected();
    }
  }

  async $dataLoad() {
    this.loadingStatus = ELoadingStatus.Loading;
    const $grid = this;
    const $utils = this.utils;
    const inParams = {
      $top: this.pageSize,
      $skip: this.pageSkip,
      material_id:  $grid.inParams.material_id ,
      packaging_id:  $grid.inParams.packaging_id ,
      full_text_search:  $grid.fullTextSearch ,
    };
    try {
    const data = await this.datasources.FootPrintManager.ds_sales_order_line_kit_grid.getList(inParams);
      this.entities = data.result;
      this.totalCount = data.totalCount;
      await this.$dataLoaded();
      if (this.totalCount === 0) {
        this.loadingStatus = ELoadingStatus.NoResults;
      } else {
        this.loadingStatus = ELoadingStatus.Loaded;
      }
    } catch(error) {
      console.error("Error loading data:", error);
      this.loadingStatus = ELoadingStatus.Error;
    }
  }

  async $dataLoaded() {
    const $grid = this;
    const $utils = this.utils;
    
    this.clearRows();

    if(this.entities) {
      let rowLoadPromises = [];
      for (let entity of this.entities) {
        const row = new FootPrintManager_sales_order_line_kit_gridComponentRowModel(
          this.utils,
          this.settings,
          this.shell, 
          this.datasources,
          this.flows,
          this.reports,
          this.localization,
          this.operations,
          this.logger);
        rowLoadPromises.push( row.$initializeExisting(this, entity, this.$rowPropertyChangeCallback.bind(this)));
        this.rows.push(row);
      }
      await Promise.all(rowLoadPromises);
    }

    await this.on_grid_data_loaded();
  }

  clearRows() {
    if(this.rows && this.rows.length) {
      this.rows.forEach(r => r.destroy());
    }
    this.rows = [];
  }


  
  openImageViewer(imageSource: string) {
    this.shell.openImageViewerDialog(imageSource);
  }

  //#region private flows
  on_grid_data_loaded(event = null) {
    return this.on_grid_data_loadedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_grid_data_loadedInternal(
    $grid: FootPrintManager_sales_order_line_kit_gridComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  const grid_rows = $grid.rows;
  
  if ($utils.isDefined(grid_rows)) {
  
      // Define the structure of the object
      interface component_lines {
          material_id: number;
          packaging_id: number;
          packaged_amount: number;
          original_material_id: number;
          original_packaging_id: number
      }
  
  
      // Initialize grid variable
      $grid.vars.lines = [];
  
      // Loop through the data and push each item into the object array
      grid_rows.forEach(row => {
          const component_line: component_lines = {
              material_id: row.entity.ComponentMaterialId,
              packaging_id: row.entity.ComponentPackagedId,
              packaged_amount: row.entity.ComponentPackagedAmount * $grid.inParams.packaged_amount,
              original_material_id: row.entity.ComponentMaterialId,
              original_packaging_id: row.entity.ComponentPackagedId,
          };
  
          $grid.vars.lines.push(component_line);
      });
  
  
  
  }
  }
  on_create_kit_components(event = null) {
    return this.on_create_kit_componentsInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_create_kit_componentsInternal(
    $grid: FootPrintManager_sales_order_line_kit_gridComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  
  const confirmation = (await $shell.FootPrintManager.openConfirmationDialog('Expand kit components', 'Do you want to convert the parent kit using the currently defined components?', 'Confirm', 'Cancel'));
  
  
  if (confirmation) {
  
      const var_lines = $grid.vars.lines;
  
      if ($utils.isDefined(var_lines)) {
  
          for (let var_line of var_lines) {
  
              if ($utils.isDefined(var_line.packaged_amount) && var_line.packaged_amount > 0) {
  
                  const orderLine = (await $flows.SalesOrders.create_sales_order_line_flow({
                      orderId: $grid.inParams.order_id,
                      packagedAmount: var_line.packaged_amount,
                      materialId: var_line.material_id,
                      packagingId: var_line.packaging_id,
                      shipmentId: $grid.inParams.shipment_id
                  }));
                  if ($utils.isDefined(orderLine)) {
  
                      if (!$utils.isDefined(orderLine.linenumber)) {
  
                          throw new Error('Unable to create order line.')
                      }
                      else {
  
                          let payload: any = {};
  
                          if ($utils.isDefined($grid.inParams.line_number)) {
                              payload.ParentLineNumber = $grid.inParams.line_number;
                          }
  
                          if (Object.keys(payload).length > 0) {
                              await $flows.Utilities.crud_update_dual_pk_flow({
                                  entitySet: 'OrderLines',
                                  pk1String: 'OrderId',
                                  pk1: $grid.inParams.order_id,
                                  pk2String: 'LineNumber',
                                  pk2: orderLine.linenumber,
                                  entity: payload
                              });
                          }
                      }
                  }
                  else {
                      throw new Error('Unable to create order line.')
                  }
  
              }
          }
  
          // Refresh grid
          $grid.refresh();
  
      }
  
  }
  }
  on_revert_changes(event = null) {
    return this.on_revert_changesInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_revert_changesInternal(
    $grid: FootPrintManager_sales_order_line_kit_gridComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  
  
  //Refresh grid
  
  $grid.refresh();
  }
  //#endregion private flows


 
  close() {
    this.$finish.emit();
  }
}
