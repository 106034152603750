import { Injectable } from '@angular/core';
import { ParamMap } from '@angular/router';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { isNil, cloneDeep } from 'lodash-es';

import { ShellService, EModalSize, EToasterType, EToasterPosition } from './shell.service'
export { EModalSize, EToasterType, EToasterPosition } from './shell.service';
import { ToastrService } from 'ngx-toastr';
import { CleanupLoggerService } from './cleanup.logging.service';
import { app_footprint_wms_homeComponent } from './app.footprint_wms_home.component';
import { app_custom_licenseplate_editorComponent } from './app.custom_licenseplate_editor.component';
import { app_custom_purchase_order_received_inventory_gridComponent } from './app.custom_purchase_order_received_inventory_grid.component';
import { app_custom_outbound_bill_of_lading_reportComponent } from './app.custom_outbound_bill_of_lading_report.component';
import { app_custom_pick_slip_reportComponent } from './app.custom_pick_slip_report.component';
import { app_custom_report_invoiceComponent } from './app.custom_report_invoice.component';

import { Document360_ShellService } from './Document360.shell.service';
import { Usersnap_ShellService } from './Usersnap.shell.service';
import { Utilities_ShellService } from './Utilities.shell.service';
import { Locations_ShellService } from './Locations.shell.service';
import { Lots_ShellService } from './Lots.shell.service';
import { PurchaseOrders_ShellService } from './PurchaseOrders.shell.service';
import { Materials_ShellService } from './Materials.shell.service';
import { FootPrintManager_ShellService } from './FootPrintManager.shell.service';

@Injectable({ providedIn: 'root' })
export class app_ShellService extends ShellService  {
  constructor(
    dialog : MatDialog,
    toastr: ToastrService,
    private logger: CleanupLoggerService,
    public Document360: Document360_ShellService,
    public Usersnap: Usersnap_ShellService,
    public Utilities: Utilities_ShellService,
    public Locations: Locations_ShellService,
    public Lots: Lots_ShellService,
    public PurchaseOrders: PurchaseOrders_ShellService,
    public Materials: Materials_ShellService,
    public FootPrintManager: FootPrintManager_ShellService,
  ) {
    super(dialog, toastr);
  }

  public app: app_ShellService = this;

  // wizards shouldn't be opened in blades (hacky check with "#unless steps" to recognize the config type)
  public openfootprint_wms_home(replaceCurrentView?: boolean) {
    this.logger.log('app', 'footprint_wms_home');
    ShellService.openViewRequest$.next(
      {
        title: 'Home',
        referenceName: 'footprint_wms_home',
        component: app_footprint_wms_homeComponent,
        replaceCurrentView: replaceCurrentView,
        
      }
    );
  }

  public openfootprint_wms_homeDialog(
    
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    this.logger.log('app', 'footprint_wms_home');
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      app_footprint_wms_homeComponent,
      'Home',
      mode,
      dialogSize
    )
  }
  public opencustom_licenseplate_editor(inParams:{ licenseplateId: number }, replaceCurrentView?: boolean) {
    this.logger.log('app', 'custom_licenseplate_editor');
    ShellService.openViewRequest$.next(
      {
        title: 'Edit Licenseplate',
        referenceName: 'custom_licenseplate_editor',
        component: app_custom_licenseplate_editorComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public opencustom_licenseplate_editorDialog(
    inParams:{ licenseplateId: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<{ confirm?: boolean }> {
    this.logger.log('app', 'custom_licenseplate_editor');
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      app_custom_licenseplate_editorComponent,
      'Edit Licenseplate',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public opencustom_purchase_order_received_inventory_grid(inParams:{ orderId: number, shipmentId: number }, replaceCurrentView?: boolean) {
    this.logger.log('app', 'custom_purchase_order_received_inventory_grid');
    ShellService.openViewRequest$.next(
      {
        title: 'Received Inventory',
        referenceName: 'custom_purchase_order_received_inventory_grid',
        component: app_custom_purchase_order_received_inventory_gridComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public opencustom_purchase_order_received_inventory_gridDialog(
    inParams:{ orderId: number, shipmentId: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    this.logger.log('app', 'custom_purchase_order_received_inventory_grid');
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      app_custom_purchase_order_received_inventory_gridComponent,
      'Received Inventory',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public opencustom_outbound_bill_of_lading_report(inParams:{ orderId?: number }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Outbound bill of lading',
        referenceName: 'custom_outbound_bill_of_lading_report',
        component: app_custom_outbound_bill_of_lading_reportComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public opencustom_outbound_bill_of_lading_reportDialog(
    inParams:{ orderId?: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Xlarge;
    }

    return this.openDialog(
      app_custom_outbound_bill_of_lading_reportComponent,
      'Outbound bill of lading',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public opencustom_pick_slip_report(inParams:{ orderId: number }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Pick Slip',
        referenceName: 'custom_pick_slip_report',
        component: app_custom_pick_slip_reportComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public opencustom_pick_slip_reportDialog(
    inParams:{ orderId: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Xlarge;
    }

    return this.openDialog(
      app_custom_pick_slip_reportComponent,
      'Pick Slip',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public opencustom_report_invoice(inParams:{ invoiceId?: number }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Invoice',
        referenceName: 'custom_report_invoice',
        component: app_custom_report_invoiceComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public opencustom_report_invoiceDialog(
    inParams:{ invoiceId?: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Xlarge;
    }

    return this.openDialog(
      app_custom_report_invoiceComponent,
      'Invoice',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }

  public getComponentInformation(referenceName: string, params: ParamMap): { title: string, component: any, inParams: any } {
    if (referenceName === 'footprint_wms_home') {
      this.logger.log('app', 'footprint_wms_home');
      const title = 'Home';
      const component = app_footprint_wms_homeComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'custom_licenseplate_editor') {
      this.logger.log('app', 'custom_licenseplate_editor');
      const title = 'Edit Licenseplate';
      const component = app_custom_licenseplate_editorComponent;
      const inParams:{ licenseplateId: number } = { licenseplateId: null };
      if (!isNil(params.get('licenseplateId'))) {
        const paramValueString = params.get('licenseplateId');
        inParams.licenseplateId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'custom_purchase_order_received_inventory_grid') {
      this.logger.log('app', 'custom_purchase_order_received_inventory_grid');
      const title = 'Received Inventory';
      const component = app_custom_purchase_order_received_inventory_gridComponent;
      const inParams:{ orderId: number, shipmentId: number } = { orderId: null, shipmentId: null };
      if (!isNil(params.get('orderId'))) {
        const paramValueString = params.get('orderId');
        inParams.orderId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('shipmentId'))) {
        const paramValueString = params.get('shipmentId');
        inParams.shipmentId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'custom_outbound_bill_of_lading_report') {
      const title = 'Outbound bill of lading';
      const component = app_custom_outbound_bill_of_lading_reportComponent;
      const inParams:{ orderId?: number } = { orderId: null };
      if (!isNil(params.get('orderId'))) {
        const paramValueString = params.get('orderId');
        inParams.orderId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'custom_pick_slip_report') {
      const title = 'Pick Slip';
      const component = app_custom_pick_slip_reportComponent;
      const inParams:{ orderId: number } = { orderId: null };
      if (!isNil(params.get('orderId'))) {
        const paramValueString = params.get('orderId');
        inParams.orderId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'custom_report_invoice') {
      const title = 'Invoice';
      const component = app_custom_report_invoiceComponent;
      const inParams:{ invoiceId?: number } = { invoiceId: null };
      if (!isNil(params.get('invoiceId'))) {
        const paramValueString = params.get('invoiceId');
        inParams.invoiceId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }

    let result = null;
    result = this.Document360.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    result = this.Usersnap.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    result = this.Utilities.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    result = this.Locations.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    result = this.Lots.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    result = this.PurchaseOrders.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    result = this.Materials.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    result = this.FootPrintManager.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    return result;
  }
}
