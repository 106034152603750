import { Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { ReportBaseService } from './report-base.service';

import { UtilsService } from './utils.service';
import { app_DatasourceService } from './app.datasource.index';

interface Iapp_custom_outbound_bill_of_lading_reportServiceInParams {
  orderId?: number}

interface Iapp_custom_outbound_bill_of_lading_reportServiceData {
  header?: { result?: { Id?: number, AccountId?: number, BillingAddresId?: number, CreatedSysUser?: string, LookupCode?: string, Notes?: string, OwnerReference?: string, ProjectId?: number, VendorReference?: string, ShipmentOrderLookups?: { OrderId?: number, ShipmentId?: number, Shipment?: { Id?: number, ActualWarehouseId?: number, BillOfLading?: string, CarrierId?: number, LookupCode?: string, PalletCount?: number, SealId?: string, ShippedDate?: string, TrailerId?: string } }[], Project?: { Id?: number, OwnerId?: number, Owner?: { Id?: number, Name?: string } }, warehouse?: { Id?: number, Name?: string, WarehousesContactsLookup?: { WarehouseId?: number, ContactId?: number }[], whaddr?: { Id?: number, AddressId?: number, PrimaryFax?: string, PrimaryTelephone?: string, Address?: { Id?: number, City?: string, Country?: string, Line1?: string, Line2?: string, PostalCode?: string, State?: string } } }, carrier?: { Id?: number, Name?: string }, shipandpymtterm?: { Id?: number, BillOfLading?: string, LookupCode?: string, PalletCount?: number, SealId?: string, ShippedDate?: string, TrailerId?: string, PaymentTerm?: { Id?: number, Name?: string } }, ordaddr?: { Id?: number, AttentionOf?: string, City?: string, Country?: string, Line1?: string, Line2?: string, Orderid?: number, PostalCode?: string, State?: string }, projaddr?: { Id?: number, AddressId?: number, OwnerId?: number, Address?: { Id?: number, AttentionOf?: string, City?: string, Country?: string, Line1?: string, Line2?: string, PostalCode?: string, State?: string } } } }
  details?: { result?: { Id?: number, ActualPackagedAmount?: number, NetWeight?: number, GrossWeight?: number, ActualTargetLicensePlateId?: number, MaterialId?: number, ActualPackagedPackId?: number, linedetails?: { Id?: number, ActualPackagedPackId?: number, ActualTargetLicensePlateId?: number, MaterialId?: number, ShipmentId?: number, ActualTargetLicensePlate?: { Id?: number, LookupCode?: string, ShipmentId?: number }, Material?: { Id?: number, Description?: string, LookupCode?: string, Name?: string }, ActualPackagedPack?: { Id?: number, DimensionUomId?: number, ShippingWeight?: number, Weight?: number, WeightUomId?: number, DimensionsUom?: { Id?: number, Short_name?: string }, WeightUom?: { Id?: number, Short_name?: string } }, IsPicked?: boolean } }[] }
}

@Injectable({ providedIn: 'root' })
export class app_custom_outbound_bill_of_lading_reportService extends ReportBaseService<Iapp_custom_outbound_bill_of_lading_reportServiceInParams, Iapp_custom_outbound_bill_of_lading_reportServiceData> {

  protected reportReferenceName = 'custom_outbound_bill_of_lading_report';
  protected appReferenceName = 'app';

  constructor(
    utils: UtilsService,
    private datasources: app_DatasourceService,
    ) {
      super(utils);
  }

  override throwIfMissingRequiredInParams (inParams: Iapp_custom_outbound_bill_of_lading_reportServiceInParams) {
  }

  protected async getData(inParams: Iapp_custom_outbound_bill_of_lading_reportServiceInParams): Promise<Iapp_custom_outbound_bill_of_lading_reportServiceData> 
  {
    const $utils = this.utils;
    const $report: { inParams: Iapp_custom_outbound_bill_of_lading_reportServiceInParams } = {
      inParams: inParams
    };

    const data: Iapp_custom_outbound_bill_of_lading_reportServiceData = {
    };
    const datasourcesQueries: (() => Promise<void>)[] = [];

    datasourcesQueries.push(async () => {
      const dsParams = {
        orderId:  $report.inParams.orderId 
      };
      
      const dsData = await this.datasources.app.custom_ds_report_outbolsalesorders_header.get(dsParams);
      
      data.header = dsData;
    });
    datasourcesQueries.push(async () => {
      const dsParams = {
        orderId:  $report.inParams.orderId 
      };
      
      const dsData = await this.datasources.app.custom_ds_report_outbolsalesorders_lines.get(dsParams);
      
      data.details = dsData;
    });

    await Promise.all(datasourcesQueries.map(t => t()));

    return data;
  }
}
