import { Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { ReportBaseService } from './report-base.service';

import { UtilsService } from './utils.service';
import { app_DatasourceService } from './app.datasource.index';

interface Iapp_custom_report_invoiceServiceInParams {
  invoiceId?: number}

interface Iapp_custom_report_invoiceServiceData {
  header?: { result?: { Id?: number, InvoiceDate?: string, LookupCode?: string, ProjectId?: number, Project?: { Id?: number, OwnerId?: number, Owner?: { Id?: number, Name?: string } }, owner?: { OwnerId?: number, ContactId?: number, Contact?: { Id?: number, AddressId?: number, FirstName?: string, LastName?: string, TypeId?: number, Type?: { Id?: number, Name?: string }, Address?: { Id?: number, City?: string, Country?: string, Line1?: string, Line2?: string, PostalCode?: string, State?: string } } } } }
  details?: { result?: { Id?: number, BillingCodeId?: number, InvoiceId?: number, LineNumber?: number, LineTotal?: number, Quantity?: number, UnitPrice?: number, BillingCode?: { Id?: number, Name?: string }, BillingRecords?: { BillingTaskId?: number, InvoiceLineId?: number }[], billingTask?: { Id?: number, MaterialId?: number, PackagingId?: number, Packaging?: { Id?: number, DimensionUomId?: number, Length?: number, Weight?: number, WeightUomId?: number, Width?: number, WeightUom?: { Id?: number, Name?: string, Short_name?: string }, DimensionsUom?: { Id?: number, Name?: string, Short_name?: string } }, BillingContractLine?: { Id?: number, BillingAggregationStrategyId?: number, BillingAggregationStrategy?: { Id?: number, EnumName?: string } } }, AmtDue?: number }[] }
}

@Injectable({ providedIn: 'root' })
export class app_custom_report_invoiceService extends ReportBaseService<Iapp_custom_report_invoiceServiceInParams, Iapp_custom_report_invoiceServiceData> {

  protected reportReferenceName = 'custom_report_invoice';
  protected appReferenceName = 'app';

  constructor(
    utils: UtilsService,
    private datasources: app_DatasourceService,
    ) {
      super(utils);
  }

  override throwIfMissingRequiredInParams (inParams: Iapp_custom_report_invoiceServiceInParams) {
  }

  protected async getData(inParams: Iapp_custom_report_invoiceServiceInParams): Promise<Iapp_custom_report_invoiceServiceData> 
  {
    const $utils = this.utils;
    const $report: { inParams: Iapp_custom_report_invoiceServiceInParams } = {
      inParams: inParams
    };

    const data: Iapp_custom_report_invoiceServiceData = {
    };
    const datasourcesQueries: (() => Promise<void>)[] = [];

    datasourcesQueries.push(async () => {
      const dsParams = {
        invoiceId:  $report.inParams.invoiceId 
      };
      
      const dsData = await this.datasources.app.custom_ds_report_invoice_header.get(dsParams);
      
      data.header = dsData;
    });
    datasourcesQueries.push(async () => {
      const dsParams = {
        invoiceId:  $report.inParams.invoiceId 
      };
      
      const dsData = await this.datasources.app.custom_ds_report_invoice_lines.get(dsParams);
      
      data.details = dsData;
    });

    await Promise.all(datasourcesQueries.map(t => t()));

    return data;
  }
}
