import { Inject, Injectable, Injector }from '@angular/core';

import { Document360_DatasourceService } from './Document360.datasource.index';
import { Usersnap_DatasourceService } from './Usersnap.datasource.index';
import { Utilities_DatasourceService } from './Utilities.datasource.index';
import { Locations_DatasourceService } from './Locations.datasource.index';
import { Lots_DatasourceService } from './Lots.datasource.index';
import { PurchaseOrders_DatasourceService } from './PurchaseOrders.datasource.index';
import { Materials_DatasourceService } from './Materials.datasource.index';
import { FootPrintManager_DatasourceService } from './FootPrintManager.datasource.index';

import { app_custom_ds_licenseplate_editorService } from './app.datasource.index';
import { app_custom_ds_received_inventory_gridService } from './app.datasource.index';
import { app_custom_ds_report_invoice_get_billingtask_detailsService } from './app.datasource.index';
import { app_custom_ds_report_invoice_get_owner_detailsService } from './app.datasource.index';
import { app_custom_ds_report_invoice_headerService } from './app.datasource.index';
import { app_custom_ds_report_invoice_linesService } from './app.datasource.index';
import { app_custom_ds_report_outbolsalesorders_get_carrier_detailsService } from './app.datasource.index';
import { app_custom_ds_report_outbolsalesorders_get_line_detailsService } from './app.datasource.index';
import { app_custom_ds_report_outbolsalesorders_get_ordaddr_detailsService } from './app.datasource.index';
import { app_custom_ds_report_outbolsalesorders_get_projaddr_detailsService } from './app.datasource.index';
import { app_custom_ds_report_outbolsalesorders_get_shipandpymtterm_detailsService } from './app.datasource.index';
import { app_custom_ds_report_outbolsalesorders_get_warehouse_detailsService } from './app.datasource.index';
import { app_custom_ds_report_outbolsalesorders_get_whaddr_detailsService } from './app.datasource.index';
import { app_custom_ds_report_outbolsalesorders_headerService } from './app.datasource.index';
import { app_custom_ds_report_outbolsalesorders_linesService } from './app.datasource.index';
import { app_custom_salesorders_ds_get_material_packaging_by_materialId_and_packagingId_top1Service } from './app.datasource.index';
import { app_custom_salesorders_ds_get_order_account_address_by_orderIdService } from './app.datasource.index';
import { app_custom_salesorders_ds_get_order_address_by_orderId_top1Service } from './app.datasource.index';
import { app_custom_salesorders_ds_get_order_owner_contact_top1Service } from './app.datasource.index';
import { app_custom_salesorders_ds_get_order_ship_to_account_vs_order_addressService } from './app.datasource.index';
import { app_custom_salesorders_ds_outbound_detail_pick_slip_reportService } from './app.datasource.index';
import { app_custom_salesorders_ds_outbound_header_pick_slip_reportService } from './app.datasource.index';

@Injectable({ providedIn: 'root' })
export class app_DatasourceService {

  constructor(
    private injector: Injector
  ) {
    this.Document360 = this.injector.get(Document360_DatasourceService);
    this.Usersnap = this.injector.get(Usersnap_DatasourceService);
    this.Utilities = this.injector.get(Utilities_DatasourceService);
    this.Locations = this.injector.get(Locations_DatasourceService);
    this.Lots = this.injector.get(Lots_DatasourceService);
    this.PurchaseOrders = this.injector.get(PurchaseOrders_DatasourceService);
    this.Materials = this.injector.get(Materials_DatasourceService);
    this.FootPrintManager = this.injector.get(FootPrintManager_DatasourceService);
  }

    public Document360: Document360_DatasourceService;
    public Usersnap: Usersnap_DatasourceService;
    public Utilities: Utilities_DatasourceService;
    public Locations: Locations_DatasourceService;
    public Lots: Lots_DatasourceService;
    public PurchaseOrders: PurchaseOrders_DatasourceService;
    public Materials: Materials_DatasourceService;
    public FootPrintManager: FootPrintManager_DatasourceService;
  public app: app_DatasourceService = this;

  // injecting lazily in order to avoid circular dependencies
  private _custom_ds_licenseplate_editor: app_custom_ds_licenseplate_editorService;
  public get custom_ds_licenseplate_editor(): app_custom_ds_licenseplate_editorService {
    if(!this._custom_ds_licenseplate_editor) {
      this._custom_ds_licenseplate_editor = this.injector.get(app_custom_ds_licenseplate_editorService);
    }
    return this._custom_ds_licenseplate_editor;
  }
  private _custom_ds_received_inventory_grid: app_custom_ds_received_inventory_gridService;
  public get custom_ds_received_inventory_grid(): app_custom_ds_received_inventory_gridService {
    if(!this._custom_ds_received_inventory_grid) {
      this._custom_ds_received_inventory_grid = this.injector.get(app_custom_ds_received_inventory_gridService);
    }
    return this._custom_ds_received_inventory_grid;
  }
  private _custom_ds_report_invoice_get_billingtask_details: app_custom_ds_report_invoice_get_billingtask_detailsService;
  public get custom_ds_report_invoice_get_billingtask_details(): app_custom_ds_report_invoice_get_billingtask_detailsService {
    if(!this._custom_ds_report_invoice_get_billingtask_details) {
      this._custom_ds_report_invoice_get_billingtask_details = this.injector.get(app_custom_ds_report_invoice_get_billingtask_detailsService);
    }
    return this._custom_ds_report_invoice_get_billingtask_details;
  }
  private _custom_ds_report_invoice_get_owner_details: app_custom_ds_report_invoice_get_owner_detailsService;
  public get custom_ds_report_invoice_get_owner_details(): app_custom_ds_report_invoice_get_owner_detailsService {
    if(!this._custom_ds_report_invoice_get_owner_details) {
      this._custom_ds_report_invoice_get_owner_details = this.injector.get(app_custom_ds_report_invoice_get_owner_detailsService);
    }
    return this._custom_ds_report_invoice_get_owner_details;
  }
  private _custom_ds_report_invoice_header: app_custom_ds_report_invoice_headerService;
  public get custom_ds_report_invoice_header(): app_custom_ds_report_invoice_headerService {
    if(!this._custom_ds_report_invoice_header) {
      this._custom_ds_report_invoice_header = this.injector.get(app_custom_ds_report_invoice_headerService);
    }
    return this._custom_ds_report_invoice_header;
  }
  private _custom_ds_report_invoice_lines: app_custom_ds_report_invoice_linesService;
  public get custom_ds_report_invoice_lines(): app_custom_ds_report_invoice_linesService {
    if(!this._custom_ds_report_invoice_lines) {
      this._custom_ds_report_invoice_lines = this.injector.get(app_custom_ds_report_invoice_linesService);
    }
    return this._custom_ds_report_invoice_lines;
  }
  private _custom_ds_report_outbolsalesorders_get_carrier_details: app_custom_ds_report_outbolsalesorders_get_carrier_detailsService;
  public get custom_ds_report_outbolsalesorders_get_carrier_details(): app_custom_ds_report_outbolsalesorders_get_carrier_detailsService {
    if(!this._custom_ds_report_outbolsalesorders_get_carrier_details) {
      this._custom_ds_report_outbolsalesorders_get_carrier_details = this.injector.get(app_custom_ds_report_outbolsalesorders_get_carrier_detailsService);
    }
    return this._custom_ds_report_outbolsalesorders_get_carrier_details;
  }
  private _custom_ds_report_outbolsalesorders_get_line_details: app_custom_ds_report_outbolsalesorders_get_line_detailsService;
  public get custom_ds_report_outbolsalesorders_get_line_details(): app_custom_ds_report_outbolsalesorders_get_line_detailsService {
    if(!this._custom_ds_report_outbolsalesorders_get_line_details) {
      this._custom_ds_report_outbolsalesorders_get_line_details = this.injector.get(app_custom_ds_report_outbolsalesorders_get_line_detailsService);
    }
    return this._custom_ds_report_outbolsalesorders_get_line_details;
  }
  private _custom_ds_report_outbolsalesorders_get_ordaddr_details: app_custom_ds_report_outbolsalesorders_get_ordaddr_detailsService;
  public get custom_ds_report_outbolsalesorders_get_ordaddr_details(): app_custom_ds_report_outbolsalesorders_get_ordaddr_detailsService {
    if(!this._custom_ds_report_outbolsalesorders_get_ordaddr_details) {
      this._custom_ds_report_outbolsalesorders_get_ordaddr_details = this.injector.get(app_custom_ds_report_outbolsalesorders_get_ordaddr_detailsService);
    }
    return this._custom_ds_report_outbolsalesorders_get_ordaddr_details;
  }
  private _custom_ds_report_outbolsalesorders_get_projaddr_details: app_custom_ds_report_outbolsalesorders_get_projaddr_detailsService;
  public get custom_ds_report_outbolsalesorders_get_projaddr_details(): app_custom_ds_report_outbolsalesorders_get_projaddr_detailsService {
    if(!this._custom_ds_report_outbolsalesorders_get_projaddr_details) {
      this._custom_ds_report_outbolsalesorders_get_projaddr_details = this.injector.get(app_custom_ds_report_outbolsalesorders_get_projaddr_detailsService);
    }
    return this._custom_ds_report_outbolsalesorders_get_projaddr_details;
  }
  private _custom_ds_report_outbolsalesorders_get_shipandpymtterm_details: app_custom_ds_report_outbolsalesorders_get_shipandpymtterm_detailsService;
  public get custom_ds_report_outbolsalesorders_get_shipandpymtterm_details(): app_custom_ds_report_outbolsalesorders_get_shipandpymtterm_detailsService {
    if(!this._custom_ds_report_outbolsalesorders_get_shipandpymtterm_details) {
      this._custom_ds_report_outbolsalesorders_get_shipandpymtterm_details = this.injector.get(app_custom_ds_report_outbolsalesorders_get_shipandpymtterm_detailsService);
    }
    return this._custom_ds_report_outbolsalesorders_get_shipandpymtterm_details;
  }
  private _custom_ds_report_outbolsalesorders_get_warehouse_details: app_custom_ds_report_outbolsalesorders_get_warehouse_detailsService;
  public get custom_ds_report_outbolsalesorders_get_warehouse_details(): app_custom_ds_report_outbolsalesorders_get_warehouse_detailsService {
    if(!this._custom_ds_report_outbolsalesorders_get_warehouse_details) {
      this._custom_ds_report_outbolsalesorders_get_warehouse_details = this.injector.get(app_custom_ds_report_outbolsalesorders_get_warehouse_detailsService);
    }
    return this._custom_ds_report_outbolsalesorders_get_warehouse_details;
  }
  private _custom_ds_report_outbolsalesorders_get_whaddr_details: app_custom_ds_report_outbolsalesorders_get_whaddr_detailsService;
  public get custom_ds_report_outbolsalesorders_get_whaddr_details(): app_custom_ds_report_outbolsalesorders_get_whaddr_detailsService {
    if(!this._custom_ds_report_outbolsalesorders_get_whaddr_details) {
      this._custom_ds_report_outbolsalesorders_get_whaddr_details = this.injector.get(app_custom_ds_report_outbolsalesorders_get_whaddr_detailsService);
    }
    return this._custom_ds_report_outbolsalesorders_get_whaddr_details;
  }
  private _custom_ds_report_outbolsalesorders_header: app_custom_ds_report_outbolsalesorders_headerService;
  public get custom_ds_report_outbolsalesorders_header(): app_custom_ds_report_outbolsalesorders_headerService {
    if(!this._custom_ds_report_outbolsalesorders_header) {
      this._custom_ds_report_outbolsalesorders_header = this.injector.get(app_custom_ds_report_outbolsalesorders_headerService);
    }
    return this._custom_ds_report_outbolsalesorders_header;
  }
  private _custom_ds_report_outbolsalesorders_lines: app_custom_ds_report_outbolsalesorders_linesService;
  public get custom_ds_report_outbolsalesorders_lines(): app_custom_ds_report_outbolsalesorders_linesService {
    if(!this._custom_ds_report_outbolsalesorders_lines) {
      this._custom_ds_report_outbolsalesorders_lines = this.injector.get(app_custom_ds_report_outbolsalesorders_linesService);
    }
    return this._custom_ds_report_outbolsalesorders_lines;
  }
  private _custom_salesorders_ds_get_material_packaging_by_materialId_and_packagingId_top1: app_custom_salesorders_ds_get_material_packaging_by_materialId_and_packagingId_top1Service;
  public get custom_salesorders_ds_get_material_packaging_by_materialId_and_packagingId_top1(): app_custom_salesorders_ds_get_material_packaging_by_materialId_and_packagingId_top1Service {
    if(!this._custom_salesorders_ds_get_material_packaging_by_materialId_and_packagingId_top1) {
      this._custom_salesorders_ds_get_material_packaging_by_materialId_and_packagingId_top1 = this.injector.get(app_custom_salesorders_ds_get_material_packaging_by_materialId_and_packagingId_top1Service);
    }
    return this._custom_salesorders_ds_get_material_packaging_by_materialId_and_packagingId_top1;
  }
  private _custom_salesorders_ds_get_order_account_address_by_orderId: app_custom_salesorders_ds_get_order_account_address_by_orderIdService;
  public get custom_salesorders_ds_get_order_account_address_by_orderId(): app_custom_salesorders_ds_get_order_account_address_by_orderIdService {
    if(!this._custom_salesorders_ds_get_order_account_address_by_orderId) {
      this._custom_salesorders_ds_get_order_account_address_by_orderId = this.injector.get(app_custom_salesorders_ds_get_order_account_address_by_orderIdService);
    }
    return this._custom_salesorders_ds_get_order_account_address_by_orderId;
  }
  private _custom_salesorders_ds_get_order_address_by_orderId_top1: app_custom_salesorders_ds_get_order_address_by_orderId_top1Service;
  public get custom_salesorders_ds_get_order_address_by_orderId_top1(): app_custom_salesorders_ds_get_order_address_by_orderId_top1Service {
    if(!this._custom_salesorders_ds_get_order_address_by_orderId_top1) {
      this._custom_salesorders_ds_get_order_address_by_orderId_top1 = this.injector.get(app_custom_salesorders_ds_get_order_address_by_orderId_top1Service);
    }
    return this._custom_salesorders_ds_get_order_address_by_orderId_top1;
  }
  private _custom_salesorders_ds_get_order_owner_contact_top1: app_custom_salesorders_ds_get_order_owner_contact_top1Service;
  public get custom_salesorders_ds_get_order_owner_contact_top1(): app_custom_salesorders_ds_get_order_owner_contact_top1Service {
    if(!this._custom_salesorders_ds_get_order_owner_contact_top1) {
      this._custom_salesorders_ds_get_order_owner_contact_top1 = this.injector.get(app_custom_salesorders_ds_get_order_owner_contact_top1Service);
    }
    return this._custom_salesorders_ds_get_order_owner_contact_top1;
  }
  private _custom_salesorders_ds_get_order_ship_to_account_vs_order_address: app_custom_salesorders_ds_get_order_ship_to_account_vs_order_addressService;
  public get custom_salesorders_ds_get_order_ship_to_account_vs_order_address(): app_custom_salesorders_ds_get_order_ship_to_account_vs_order_addressService {
    if(!this._custom_salesorders_ds_get_order_ship_to_account_vs_order_address) {
      this._custom_salesorders_ds_get_order_ship_to_account_vs_order_address = this.injector.get(app_custom_salesorders_ds_get_order_ship_to_account_vs_order_addressService);
    }
    return this._custom_salesorders_ds_get_order_ship_to_account_vs_order_address;
  }
  private _custom_salesorders_ds_outbound_detail_pick_slip_report: app_custom_salesorders_ds_outbound_detail_pick_slip_reportService;
  public get custom_salesorders_ds_outbound_detail_pick_slip_report(): app_custom_salesorders_ds_outbound_detail_pick_slip_reportService {
    if(!this._custom_salesorders_ds_outbound_detail_pick_slip_report) {
      this._custom_salesorders_ds_outbound_detail_pick_slip_report = this.injector.get(app_custom_salesorders_ds_outbound_detail_pick_slip_reportService);
    }
    return this._custom_salesorders_ds_outbound_detail_pick_slip_report;
  }
  private _custom_salesorders_ds_outbound_header_pick_slip_report: app_custom_salesorders_ds_outbound_header_pick_slip_reportService;
  public get custom_salesorders_ds_outbound_header_pick_slip_report(): app_custom_salesorders_ds_outbound_header_pick_slip_reportService {
    if(!this._custom_salesorders_ds_outbound_header_pick_slip_report) {
      this._custom_salesorders_ds_outbound_header_pick_slip_report = this.injector.get(app_custom_salesorders_ds_outbound_header_pick_slip_reportService);
    }
    return this._custom_salesorders_ds_outbound_header_pick_slip_report;
  }
}

