import { Inject, Injectable, Injector } from '@angular/core';

import { Document360_ReportService } from './Document360.report.index';
import { Usersnap_ReportService } from './Usersnap.report.index';
import { Utilities_ReportService } from './Utilities.report.index';
import { Locations_ReportService } from './Locations.report.index';
import { Lots_ReportService } from './Lots.report.index';
import { PurchaseOrders_ReportService } from './PurchaseOrders.report.index';
import { Materials_ReportService } from './Materials.report.index';
import { FootPrintManager_ReportService } from './FootPrintManager.report.index';

import { app_custom_outbound_bill_of_lading_reportService } from './app.report.index';
import { app_custom_pick_slip_reportService } from './app.report.index';
import { app_custom_report_invoiceService } from './app.report.index';

@Injectable({ providedIn: 'root' })
export class app_ReportService {

  constructor(
    private injector: Injector
  ) {
    this.Document360 = this.injector.get(Document360_ReportService);
    this.Usersnap = this.injector.get(Usersnap_ReportService);
    this.Utilities = this.injector.get(Utilities_ReportService);
    this.Locations = this.injector.get(Locations_ReportService);
    this.Lots = this.injector.get(Lots_ReportService);
    this.PurchaseOrders = this.injector.get(PurchaseOrders_ReportService);
    this.Materials = this.injector.get(Materials_ReportService);
    this.FootPrintManager = this.injector.get(FootPrintManager_ReportService);
  }
    public Document360: Document360_ReportService;
    public Usersnap: Usersnap_ReportService;
    public Utilities: Utilities_ReportService;
    public Locations: Locations_ReportService;
    public Lots: Lots_ReportService;
    public PurchaseOrders: PurchaseOrders_ReportService;
    public Materials: Materials_ReportService;
    public FootPrintManager: FootPrintManager_ReportService;

  public app: app_ReportService = this;

  // injecting lazily in order to avoid circular dependencies
  private _custom_outbound_bill_of_lading_report: app_custom_outbound_bill_of_lading_reportService;
  public get custom_outbound_bill_of_lading_report(): app_custom_outbound_bill_of_lading_reportService {
    if(!this._custom_outbound_bill_of_lading_report) {
      this._custom_outbound_bill_of_lading_report = this.injector.get(app_custom_outbound_bill_of_lading_reportService);
    }
    return this._custom_outbound_bill_of_lading_report;
  }
  private _custom_pick_slip_report: app_custom_pick_slip_reportService;
  public get custom_pick_slip_report(): app_custom_pick_slip_reportService {
    if(!this._custom_pick_slip_report) {
      this._custom_pick_slip_report = this.injector.get(app_custom_pick_slip_reportService);
    }
    return this._custom_pick_slip_report;
  }
  private _custom_report_invoice: app_custom_report_invoiceService;
  public get custom_report_invoice(): app_custom_report_invoiceService {
    if(!this._custom_report_invoice) {
      this._custom_report_invoice = this.injector.get(app_custom_report_invoiceService);
    }
    return this._custom_report_invoice;
  }
}

