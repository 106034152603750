import { Injectable } from '@angular/core';
import { ParamMap } from '@angular/router';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { isNil, cloneDeep } from 'lodash-es';

import { ShellService, EModalSize, EToasterType, EToasterPosition } from './shell.service'
export { EModalSize, EToasterType, EToasterPosition } from './shell.service';
import { ToastrService } from 'ngx-toastr';
import { CleanupLoggerService } from './cleanup.logging.service';
import { Invoices_invoices_library_homeComponent } from './Invoices.invoices_library_home.component';
import { Invoices_invoicing_instructions_editorComponent } from './Invoices.invoicing_instructions_editor.component';
import { Invoices_commodity_price_flat_gridComponent } from './Invoices.commodity_price_flat_grid.component';
import { Invoices_commodity_price_gridComponent } from './Invoices.commodity_price_grid.component';
import { Invoices_invoicing_instructions_gridComponent } from './Invoices.invoicing_instructions_grid.component';
import { Invoices_invoicing_logs_gridComponent } from './Invoices.invoicing_logs_grid.component';
import { Invoices_invoicing_options_gridComponent } from './Invoices.invoicing_options_grid.component';
import { Invoices_invoicing_transactions_gridComponent } from './Invoices.invoicing_transactions_grid.component';
import { Invoices_invoicing_instructions_hubComponent } from './Invoices.invoicing_instructions_hub.component';
import { Invoices_invoice_reportComponent } from './Invoices.invoice_report.component';
import { Invoices_view_json_payloadComponent } from './Invoices.view_json_payload.component';
import { Invoices_billing_accessorial_charges_templates_dd_singleComponent } from './Invoices.billing_accessorial_charges_templates_dd_single.component';
import { Invoices_billing_aggregation_strategies_dd_singleComponent } from './Invoices.billing_aggregation_strategies_dd_single.component';
import { Invoices_billing_contract_line_date_range_singleComponent } from './Invoices.billing_contract_line_date_range_single.component';
import { Invoices_billing_contracts_grid_days_dd_singleComponent } from './Invoices.billing_contracts_grid_days_dd_single.component';
import { Invoices_billing_contracts_status_dd_singleComponent } from './Invoices.billing_contracts_status_dd_single.component';
import { Invoices_billing_days_remaining_dd_singleComponent } from './Invoices.billing_days_remaining_dd_single.component';
import { Invoices_billing_frequency_dd_singleComponent } from './Invoices.billing_frequency_dd_single.component';
import { Invoices_billing_grace_periods_dd_singleComponent } from './Invoices.billing_grace_periods_dd_single.component';
import { Invoices_billing_initiating_date_dd_singleComponent } from './Invoices.billing_initiating_date_dd_single.component';
import { Invoices_billing_methods_dd_singleComponent } from './Invoices.billing_methods_dd_single.component';
import { Invoices_billing_objects_dd_singleComponent } from './Invoices.billing_objects_dd_single.component';
import { Invoices_billing_outbound_shipment_service_type_methods_dd_singleComponent } from './Invoices.billing_outbound_shipment_service_type_methods_dd_single.component';
import { Invoices_billing_recurring_grace_periods_dd_singleComponent } from './Invoices.billing_recurring_grace_periods_dd_single.component';
import { Invoices_billing_schedule_types_dd_singleComponent } from './Invoices.billing_schedule_types_dd_single.component';
import { Invoices_billing_weekdays_dd_singleComponent } from './Invoices.billing_weekdays_dd_single.component';
import { Invoices_billingcodes_dd_singleComponent } from './Invoices.billingcodes_dd_single.component';
import { Invoices_carrier_service_types_dd_singleComponent } from './Invoices.carrier_service_types_dd_single.component';
import { Invoices_currencies_dd_singleComponent } from './Invoices.currencies_dd_single.component';
import { Invoices_diverted_projects_dd_singleComponent } from './Invoices.diverted_projects_dd_single.component';
import { Invoices_invoice_statuses_dd_singleComponent } from './Invoices.invoice_statuses_dd_single.component';
import { Invoices_invoice_terms_dd_singleComponent } from './Invoices.invoice_terms_dd_single.component';
import { Invoices_invoice_types_dd_singleComponent } from './Invoices.invoice_types_dd_single.component';
import { Invoices_invoices_dd_singleComponent } from './Invoices.invoices_dd_single.component';
import { Invoices_invoicing_instructions_dd_singleComponent } from './Invoices.invoicing_instructions_dd_single.component';
import { Invoices_invoicing_options_dd_singleComponent } from './Invoices.invoicing_options_dd_single.component';
import { Invoices_invoicing_projects_dd_singleComponent } from './Invoices.invoicing_projects_dd_single.component';
import { Invoices_location_storage_categories_dd_singleComponent } from './Invoices.location_storage_categories_dd_single.component';
import { Invoices_measurement_units_dd_singleComponent } from './Invoices.measurement_units_dd_single.component';
import { Invoices_operation_codes_dd_singleComponent } from './Invoices.operation_codes_dd_single.component';
import { Invoices_reasoncodes_dd_singleComponent } from './Invoices.reasoncodes_dd_single.component';
import { Invoices_recurring_storage_billing_objects_dd_singleComponent } from './Invoices.recurring_storage_billing_objects_dd_single.component';
import { Invoices_recurring_storage_schedule_types_dd_singleComponent } from './Invoices.recurring_storage_schedule_types_dd_single.component';
import { Invoices_renewal_types_dd_singleComponent } from './Invoices.renewal_types_dd_single.component';
import { Invoices_tags_dd_singleComponent } from './Invoices.tags_dd_single.component';
import { Invoices_tax_schedules_dd_singleComponent } from './Invoices.tax_schedules_dd_single.component';
import { Invoices_transaction_statuses_dd_singleComponent } from './Invoices.transaction_statuses_dd_single.component';
import { Invoices_warehouses_dd_singleComponent } from './Invoices.warehouses_dd_single.component';
import { Invoices_billing_accessorial_charges_templates_dd_multiComponent } from './Invoices.billing_accessorial_charges_templates_dd_multi.component';
import { Invoices_billing_aggregation_strategies_dd_multiComponent } from './Invoices.billing_aggregation_strategies_dd_multi.component';
import { Invoices_billing_contract_line_date_range_multiComponent } from './Invoices.billing_contract_line_date_range_multi.component';
import { Invoices_billing_contracts_grid_days_dd_multiComponent } from './Invoices.billing_contracts_grid_days_dd_multi.component';
import { Invoices_billing_contracts_status_dd_multiComponent } from './Invoices.billing_contracts_status_dd_multi.component';
import { Invoices_billing_days_remaining_dd_multiComponent } from './Invoices.billing_days_remaining_dd_multi.component';
import { Invoices_billing_frequency_dd_multiComponent } from './Invoices.billing_frequency_dd_multi.component';
import { Invoices_billing_grace_periods_dd_multiComponent } from './Invoices.billing_grace_periods_dd_multi.component';
import { Invoices_billing_initiating_date_dd_multiComponent } from './Invoices.billing_initiating_date_dd_multi.component';
import { Invoices_billing_methods_dd_multiComponent } from './Invoices.billing_methods_dd_multi.component';
import { Invoices_billing_objects_dd_multiComponent } from './Invoices.billing_objects_dd_multi.component';
import { Invoices_billing_outbound_shipment_service_type_methods_dd_multiComponent } from './Invoices.billing_outbound_shipment_service_type_methods_dd_multi.component';
import { Invoices_billing_recurring_grace_periods_dd_multiComponent } from './Invoices.billing_recurring_grace_periods_dd_multi.component';
import { Invoices_billing_schedule_types_dd_multiComponent } from './Invoices.billing_schedule_types_dd_multi.component';
import { Invoices_billing_weekdays_dd_multiComponent } from './Invoices.billing_weekdays_dd_multi.component';
import { Invoices_billingcodes_dd_multiComponent } from './Invoices.billingcodes_dd_multi.component';
import { Invoices_carrier_service_types_dd_multiComponent } from './Invoices.carrier_service_types_dd_multi.component';
import { Invoices_currencies_dd_multiComponent } from './Invoices.currencies_dd_multi.component';
import { Invoices_diverted_projects_dd_multiComponent } from './Invoices.diverted_projects_dd_multi.component';
import { Invoices_invoice_statuses_dd_multiComponent } from './Invoices.invoice_statuses_dd_multi.component';
import { Invoices_invoice_terms_dd_multiComponent } from './Invoices.invoice_terms_dd_multi.component';
import { Invoices_invoice_types_dd_multiComponent } from './Invoices.invoice_types_dd_multi.component';
import { Invoices_invoices_dd_multiComponent } from './Invoices.invoices_dd_multi.component';
import { Invoices_invoicing_instructions_dd_multiComponent } from './Invoices.invoicing_instructions_dd_multi.component';
import { Invoices_invoicing_options_dd_multiComponent } from './Invoices.invoicing_options_dd_multi.component';
import { Invoices_invoicing_projects_dd_multiComponent } from './Invoices.invoicing_projects_dd_multi.component';
import { Invoices_location_storage_categories_dd_multiComponent } from './Invoices.location_storage_categories_dd_multi.component';
import { Invoices_measurement_units_dd_multiComponent } from './Invoices.measurement_units_dd_multi.component';
import { Invoices_operation_codes_dd_multiComponent } from './Invoices.operation_codes_dd_multi.component';
import { Invoices_reasoncodes_dd_multiComponent } from './Invoices.reasoncodes_dd_multi.component';
import { Invoices_recurring_storage_billing_objects_dd_multiComponent } from './Invoices.recurring_storage_billing_objects_dd_multi.component';
import { Invoices_recurring_storage_schedule_types_dd_multiComponent } from './Invoices.recurring_storage_schedule_types_dd_multi.component';
import { Invoices_renewal_types_dd_multiComponent } from './Invoices.renewal_types_dd_multi.component';
import { Invoices_tags_dd_multiComponent } from './Invoices.tags_dd_multi.component';
import { Invoices_tax_schedules_dd_multiComponent } from './Invoices.tax_schedules_dd_multi.component';
import { Invoices_transaction_statuses_dd_multiComponent } from './Invoices.transaction_statuses_dd_multi.component';
import { Invoices_warehouses_dd_multiComponent } from './Invoices.warehouses_dd_multi.component';
import { Invoices_billing_contract_lines_widgetComponent } from './Invoices.billing_contract_lines_widget.component';
import { Invoices_billing_contract_total_widgetComponent } from './Invoices.billing_contract_total_widget.component';
import { Invoices_billing_record_total_billed_by_current_month_widgetComponent } from './Invoices.billing_record_total_billed_by_current_month_widget.component';
import { Invoices_billing_record_total_billed_by_date_widgetComponent } from './Invoices.billing_record_total_billed_by_date_widget.component';
import { Invoices_billing_records_total_invoiced_by_date_widgetComponent } from './Invoices.billing_records_total_invoiced_by_date_widget.component';
import { Invoices_invoice_total_billed_widgetComponent } from './Invoices.invoice_total_billed_widget.component';
import { Invoices_invoice_total_billed_with_filters_widgetComponent } from './Invoices.invoice_total_billed_with_filters_widget.component';
import { Invoices_invoice_total_units_widgetComponent } from './Invoices.invoice_total_units_widget.component';
import { Invoices_invoice_total_units_with_filters_widgetComponent } from './Invoices.invoice_total_units_with_filters_widget.component';

import { Utilities_ShellService } from './Utilities.shell.service';

@Injectable({ providedIn: 'root' })
export class Invoices_ShellService extends ShellService  {
  constructor(
    dialog : MatDialog,
    toastr: ToastrService,
    private logger: CleanupLoggerService,
    public Utilities: Utilities_ShellService,
  ) {
    super(dialog, toastr);
  }

  public Invoices: Invoices_ShellService = this;

  // wizards shouldn't be opened in blades (hacky check with "#unless steps" to recognize the config type)
  public openinvoices_library_home(replaceCurrentView?: boolean) {
    this.logger.log('Invoices', 'invoices_library_home');
    ShellService.openViewRequest$.next(
      {
        title: 'Home',
        referenceName: 'Invoices_invoices_library_home',
        component: Invoices_invoices_library_homeComponent,
        replaceCurrentView: replaceCurrentView,
        
      }
    );
  }

  public openinvoices_library_homeDialog(
    
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    this.logger.log('Invoices', 'invoices_library_home');
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      Invoices_invoices_library_homeComponent,
      'Home',
      mode,
      dialogSize
    )
  }
  public openinvoicing_instructions_editor(inParams:{ instructionId: string, billingContractId?: number, projectId?: number, active?: boolean }, replaceCurrentView?: boolean) {
    this.logger.log('Invoices', 'invoicing_instructions_editor');
    ShellService.openViewRequest$.next(
      {
        title: 'Invoicing Instructions',
        referenceName: 'Invoices_invoicing_instructions_editor',
        component: Invoices_invoicing_instructions_editorComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openinvoicing_instructions_editorDialog(
    inParams:{ instructionId: string, billingContractId?: number, projectId?: number, active?: boolean }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    this.logger.log('Invoices', 'invoicing_instructions_editor');
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      Invoices_invoicing_instructions_editorComponent,
      'Invoicing Instructions',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public opencommodity_price_flat_grid(inParams:{ names: string[], title?: string }, replaceCurrentView?: boolean) {
    this.logger.log('Invoices', 'commodity_price_flat_grid');
    ShellService.openViewRequest$.next(
      {
        title: 'commodity_price_flat_grid',
        referenceName: 'Invoices_commodity_price_flat_grid',
        component: Invoices_commodity_price_flat_gridComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public opencommodity_price_flat_gridDialog(
    inParams:{ names: string[], title?: string }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    this.logger.log('Invoices', 'commodity_price_flat_grid');
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      Invoices_commodity_price_flat_gridComponent,
      'commodity_price_flat_grid',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public opencommodity_price_grid(inParams:{ title?: string }, replaceCurrentView?: boolean) {
    this.logger.log('Invoices', 'commodity_price_grid');
    ShellService.openViewRequest$.next(
      {
        title: 'commodity_price_grid',
        referenceName: 'Invoices_commodity_price_grid',
        component: Invoices_commodity_price_gridComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public opencommodity_price_gridDialog(
    inParams:{ title?: string }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    this.logger.log('Invoices', 'commodity_price_grid');
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      Invoices_commodity_price_gridComponent,
      'commodity_price_grid',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openinvoicing_instructions_grid(inParams:{ billingContractId?: number, projectId?: number }, replaceCurrentView?: boolean) {
    this.logger.log('Invoices', 'invoicing_instructions_grid');
    ShellService.openViewRequest$.next(
      {
        title: 'Invoicing instructions grid',
        referenceName: 'Invoices_invoicing_instructions_grid',
        component: Invoices_invoicing_instructions_gridComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openinvoicing_instructions_gridDialog(
    inParams:{ billingContractId?: number, projectId?: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    this.logger.log('Invoices', 'invoicing_instructions_grid');
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      Invoices_invoicing_instructions_gridComponent,
      'Invoicing instructions grid',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openinvoicing_logs_grid(inParams:{ billingContractId?: number, projectId?: number, invoiceId?: number, transactionId?: string, instructionId?: string, requestId?: string }, replaceCurrentView?: boolean) {
    this.logger.log('Invoices', 'invoicing_logs_grid');
    ShellService.openViewRequest$.next(
      {
        title: 'Invoicing logs grid',
        referenceName: 'Invoices_invoicing_logs_grid',
        component: Invoices_invoicing_logs_gridComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openinvoicing_logs_gridDialog(
    inParams:{ billingContractId?: number, projectId?: number, invoiceId?: number, transactionId?: string, instructionId?: string, requestId?: string }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    this.logger.log('Invoices', 'invoicing_logs_grid');
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      Invoices_invoicing_logs_gridComponent,
      'Invoicing logs grid',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openinvoicing_options_grid(replaceCurrentView?: boolean) {
    this.logger.log('Invoices', 'invoicing_options_grid');
    ShellService.openViewRequest$.next(
      {
        title: 'Invoicing options grid',
        referenceName: 'Invoices_invoicing_options_grid',
        component: Invoices_invoicing_options_gridComponent,
        replaceCurrentView: replaceCurrentView,
        
      }
    );
  }

  public openinvoicing_options_gridDialog(
    
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    this.logger.log('Invoices', 'invoicing_options_grid');
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      Invoices_invoicing_options_gridComponent,
      'Invoicing options grid',
      mode,
      dialogSize
    )
  }
  public openinvoicing_transactions_grid(inParams:{ billingContractId?: number, projectId?: number, invoiceId?: number, transactionId?: string, instructionId?: string, requestId?: string }, replaceCurrentView?: boolean) {
    this.logger.log('Invoices', 'invoicing_transactions_grid');
    ShellService.openViewRequest$.next(
      {
        title: 'invoicing_transactions_grid',
        referenceName: 'Invoices_invoicing_transactions_grid',
        component: Invoices_invoicing_transactions_gridComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openinvoicing_transactions_gridDialog(
    inParams:{ billingContractId?: number, projectId?: number, invoiceId?: number, transactionId?: string, instructionId?: string, requestId?: string }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    this.logger.log('Invoices', 'invoicing_transactions_grid');
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      Invoices_invoicing_transactions_gridComponent,
      'invoicing_transactions_grid',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openinvoicing_instructions_hub(inParams:{ projectId?: number, billingContractId?: number }, replaceCurrentView?: boolean) {
    this.logger.log('Invoices', 'invoicing_instructions_hub');
    ShellService.openViewRequest$.next(
      {
        title: 'Invoicing Instructions Hub',
        referenceName: 'Invoices_invoicing_instructions_hub',
        component: Invoices_invoicing_instructions_hubComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openinvoicing_instructions_hubDialog(
    inParams:{ projectId?: number, billingContractId?: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    this.logger.log('Invoices', 'invoicing_instructions_hub');
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      Invoices_invoicing_instructions_hubComponent,
      'Invoicing Instructions Hub',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openinvoice_report(inParams:{ invoiceId?: number }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Invoice',
        referenceName: 'Invoices_invoice_report',
        component: Invoices_invoice_reportComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openinvoice_reportDialog(
    inParams:{ invoiceId?: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Xlarge;
    }

    return this.openDialog(
      Invoices_invoice_reportComponent,
      'Invoice',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openview_json_payload(inParams:{ payload?: any, title?: string }, replaceCurrentView?: boolean) {
    this.logger.log('Invoices', 'view_json_payload');
    ShellService.openViewRequest$.next(
      {
        title: 'view_json_payload',
        referenceName: 'Invoices_view_json_payload',
        component: Invoices_view_json_payloadComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openview_json_payloadDialog(
    inParams:{ payload?: any, title?: string }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    this.logger.log('Invoices', 'view_json_payload');
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      Invoices_view_json_payloadComponent,
      'view_json_payload',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }

  public getComponentInformation(referenceName: string, params: ParamMap): { title: string, component: any, inParams: any } {
    if (referenceName === 'Invoices_invoices_library_home') {
      this.logger.log('Invoices', 'invoices_library_home');
      const title = 'Home';
      const component = Invoices_invoices_library_homeComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Invoices_invoicing_instructions_editor') {
      this.logger.log('Invoices', 'invoicing_instructions_editor');
      const title = 'Invoicing Instructions';
      const component = Invoices_invoicing_instructions_editorComponent;
      const inParams:{ instructionId: string, billingContractId?: number, projectId?: number, active?: boolean } = { instructionId: null, billingContractId: null, projectId: null, active: null };
      if (!isNil(params.get('instructionId'))) {
        const paramValueString = params.get('instructionId');
        // TODO: date
        inParams.instructionId = paramValueString;
              }
      if (!isNil(params.get('billingContractId'))) {
        const paramValueString = params.get('billingContractId');
        inParams.billingContractId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('projectId'))) {
        const paramValueString = params.get('projectId');
        inParams.projectId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('active'))) {
        const paramValueString = params.get('active');
        inParams.active = this.convertToBoolean(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Invoices_commodity_price_flat_grid') {
      this.logger.log('Invoices', 'commodity_price_flat_grid');
      const title = 'commodity_price_flat_grid';
      const component = Invoices_commodity_price_flat_gridComponent;
      const inParams:{ names: string[], title?: string } = { names: [], title: null };
      if (!isNil(params.get('names'))) {
        const paramValueString = params.get('names');
        inParams.names = this.convertToJSON(paramValueString);
      }
      if (!isNil(params.get('title'))) {
        const paramValueString = params.get('title');
        // TODO: date
        inParams.title = paramValueString;
              }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Invoices_commodity_price_grid') {
      this.logger.log('Invoices', 'commodity_price_grid');
      const title = 'commodity_price_grid';
      const component = Invoices_commodity_price_gridComponent;
      const inParams:{ title?: string } = { title: null };
      if (!isNil(params.get('title'))) {
        const paramValueString = params.get('title');
        // TODO: date
        inParams.title = paramValueString;
              }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Invoices_invoicing_instructions_grid') {
      this.logger.log('Invoices', 'invoicing_instructions_grid');
      const title = 'Invoicing instructions grid';
      const component = Invoices_invoicing_instructions_gridComponent;
      const inParams:{ billingContractId?: number, projectId?: number } = { billingContractId: null, projectId: null };
      if (!isNil(params.get('billingContractId'))) {
        const paramValueString = params.get('billingContractId');
        inParams.billingContractId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('projectId'))) {
        const paramValueString = params.get('projectId');
        inParams.projectId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Invoices_invoicing_logs_grid') {
      this.logger.log('Invoices', 'invoicing_logs_grid');
      const title = 'Invoicing logs grid';
      const component = Invoices_invoicing_logs_gridComponent;
      const inParams:{ billingContractId?: number, projectId?: number, invoiceId?: number, transactionId?: string, instructionId?: string, requestId?: string } = { billingContractId: null, projectId: null, invoiceId: null, transactionId: null, instructionId: null, requestId: null };
      if (!isNil(params.get('billingContractId'))) {
        const paramValueString = params.get('billingContractId');
        inParams.billingContractId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('projectId'))) {
        const paramValueString = params.get('projectId');
        inParams.projectId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('invoiceId'))) {
        const paramValueString = params.get('invoiceId');
        inParams.invoiceId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('transactionId'))) {
        const paramValueString = params.get('transactionId');
        // TODO: date
        inParams.transactionId = paramValueString;
              }
      if (!isNil(params.get('instructionId'))) {
        const paramValueString = params.get('instructionId');
        // TODO: date
        inParams.instructionId = paramValueString;
              }
      if (!isNil(params.get('requestId'))) {
        const paramValueString = params.get('requestId');
        // TODO: date
        inParams.requestId = paramValueString;
              }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Invoices_invoicing_options_grid') {
      this.logger.log('Invoices', 'invoicing_options_grid');
      const title = 'Invoicing options grid';
      const component = Invoices_invoicing_options_gridComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Invoices_invoicing_transactions_grid') {
      this.logger.log('Invoices', 'invoicing_transactions_grid');
      const title = 'invoicing_transactions_grid';
      const component = Invoices_invoicing_transactions_gridComponent;
      const inParams:{ billingContractId?: number, projectId?: number, invoiceId?: number, transactionId?: string, instructionId?: string, requestId?: string } = { billingContractId: null, projectId: null, invoiceId: null, transactionId: null, instructionId: null, requestId: null };
      if (!isNil(params.get('billingContractId'))) {
        const paramValueString = params.get('billingContractId');
        inParams.billingContractId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('projectId'))) {
        const paramValueString = params.get('projectId');
        inParams.projectId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('invoiceId'))) {
        const paramValueString = params.get('invoiceId');
        inParams.invoiceId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('transactionId'))) {
        const paramValueString = params.get('transactionId');
        // TODO: date
        inParams.transactionId = paramValueString;
              }
      if (!isNil(params.get('instructionId'))) {
        const paramValueString = params.get('instructionId');
        // TODO: date
        inParams.instructionId = paramValueString;
              }
      if (!isNil(params.get('requestId'))) {
        const paramValueString = params.get('requestId');
        // TODO: date
        inParams.requestId = paramValueString;
              }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Invoices_invoicing_instructions_hub') {
      this.logger.log('Invoices', 'invoicing_instructions_hub');
      const title = 'Invoicing Instructions Hub';
      const component = Invoices_invoicing_instructions_hubComponent;
      const inParams:{ projectId?: number, billingContractId?: number } = { projectId: null, billingContractId: null };
      if (!isNil(params.get('projectId'))) {
        const paramValueString = params.get('projectId');
        inParams.projectId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('billingContractId'))) {
        const paramValueString = params.get('billingContractId');
        inParams.billingContractId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Invoices_invoice_report') {
      const title = 'Invoice';
      const component = Invoices_invoice_reportComponent;
      const inParams:{ invoiceId?: number } = { invoiceId: null };
      if (!isNil(params.get('invoiceId'))) {
        const paramValueString = params.get('invoiceId');
        inParams.invoiceId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Invoices_view_json_payload') {
      this.logger.log('Invoices', 'view_json_payload');
      const title = 'view_json_payload';
      const component = Invoices_view_json_payloadComponent;
      const inParams:{ payload?: any, title?: string } = { payload: null, title: null };
      if (!isNil(params.get('payload'))) {
        const paramValueString = params.get('payload');
        inParams.payload = this.convertToJSON(paramValueString);
      }
      if (!isNil(params.get('title'))) {
        const paramValueString = params.get('title');
        // TODO: date
        inParams.title = paramValueString;
              }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Invoices_billing_accessorial_charges_templates_dd_single') {
      const title = 'Billing Accessorial Charges Templates';
      const component = Invoices_billing_accessorial_charges_templates_dd_singleComponent;
      const inParams:{ id?: number[], enabled?: boolean } = { id: [], enabled: null };
      if (!isNil(params.get('id'))) {
        const paramValueString = params.get('id');
        inParams.id = this.convertToJSON(paramValueString);
      }
      if (!isNil(params.get('enabled'))) {
        const paramValueString = params.get('enabled');
        inParams.enabled = this.convertToBoolean(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Invoices_billing_aggregation_strategies_dd_single') {
      const title = 'billing_aggregation_strategies_dd';
      const component = Invoices_billing_aggregation_strategies_dd_singleComponent;
      const inParams:{ id?: number[], name?: string } = { id: [], name: null };
      if (!isNil(params.get('id'))) {
        const paramValueString = params.get('id');
        inParams.id = this.convertToJSON(paramValueString);
      }
      if (!isNil(params.get('name'))) {
        const paramValueString = params.get('name');
        // TODO: date
        inParams.name = paramValueString;
              }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Invoices_billing_contract_line_date_range_single') {
      const title = 'billing_contract_line_date_range';
      const component = Invoices_billing_contract_line_date_range_singleComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Invoices_billing_contracts_grid_days_dd_single') {
      this.logger.log('Invoices', 'billing_contracts_grid_days_dd_single');
      const title = 'Billing Contract Days Drop Down';
      const component = Invoices_billing_contracts_grid_days_dd_singleComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Invoices_billing_contracts_status_dd_single') {
      const title = 'billing_contracts_status_dd';
      const component = Invoices_billing_contracts_status_dd_singleComponent;
      const inParams:{ statusIds?: number[] } = { statusIds: [] };
      if (!isNil(params.get('statusIds'))) {
        const paramValueString = params.get('statusIds');
        inParams.statusIds = this.convertToJSON(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Invoices_billing_days_remaining_dd_single') {
      const title = 'billing_days_remaining_dd';
      const component = Invoices_billing_days_remaining_dd_singleComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Invoices_billing_frequency_dd_single') {
      const title = 'billing_frequency_dd';
      const component = Invoices_billing_frequency_dd_singleComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Invoices_billing_grace_periods_dd_single') {
      const title = 'billing_grace_periods_dd';
      const component = Invoices_billing_grace_periods_dd_singleComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Invoices_billing_initiating_date_dd_single') {
      const title = 'billing_initiating_date_dd';
      const component = Invoices_billing_initiating_date_dd_singleComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Invoices_billing_methods_dd_single') {
      const title = 'Billing Methods';
      const component = Invoices_billing_methods_dd_singleComponent;
      const inParams:{ idsToExlude?: number[], idsToInclude?: number[] } = { idsToExlude: [], idsToInclude: [] };
      if (!isNil(params.get('idsToExlude'))) {
        const paramValueString = params.get('idsToExlude');
        inParams.idsToExlude = this.convertToJSON(paramValueString);
      }
      if (!isNil(params.get('idsToInclude'))) {
        const paramValueString = params.get('idsToInclude');
        inParams.idsToInclude = this.convertToJSON(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Invoices_billing_objects_dd_single') {
      const title = 'billing_objects_dd';
      const component = Invoices_billing_objects_dd_singleComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Invoices_billing_outbound_shipment_service_type_methods_dd_single') {
      const title = 'billing_outbound_shipment_service_type_methods_dd';
      const component = Invoices_billing_outbound_shipment_service_type_methods_dd_singleComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Invoices_billing_recurring_grace_periods_dd_single') {
      const title = 'billing_recurring_grace_periods_dd';
      const component = Invoices_billing_recurring_grace_periods_dd_singleComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Invoices_billing_schedule_types_dd_single') {
      this.logger.log('Invoices', 'billing_schedule_types_dd_single');
      const title = 'billing_schedule_types_dd';
      const component = Invoices_billing_schedule_types_dd_singleComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Invoices_billing_weekdays_dd_single') {
      const title = 'billing_weekdays_dd';
      const component = Invoices_billing_weekdays_dd_singleComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Invoices_billingcodes_dd_single') {
      const title = 'List of Billing Codes';
      const component = Invoices_billingcodes_dd_singleComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Invoices_carrier_service_types_dd_single') {
      const title = 'carrier_service_types_dd';
      const component = Invoices_carrier_service_types_dd_singleComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Invoices_currencies_dd_single') {
      const title = 'currencies_dd';
      const component = Invoices_currencies_dd_singleComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Invoices_diverted_projects_dd_single') {
      this.logger.log('Invoices', 'diverted_projects_dd_single');
      const title = 'Diverted Projects';
      const component = Invoices_diverted_projects_dd_singleComponent;
      const inParams:{ projectId: number } = { projectId: null };
      if (!isNil(params.get('projectId'))) {
        const paramValueString = params.get('projectId');
        inParams.projectId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Invoices_invoice_statuses_dd_single') {
      const title = 'List of invoice statuses.';
      const component = Invoices_invoice_statuses_dd_singleComponent;
      const inParams:{ excludedStatusId?: number, includedStatusIds?: number[] } = { excludedStatusId: null, includedStatusIds: [] };
      if (!isNil(params.get('excludedStatusId'))) {
        const paramValueString = params.get('excludedStatusId');
        inParams.excludedStatusId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('includedStatusIds'))) {
        const paramValueString = params.get('includedStatusIds');
        inParams.includedStatusIds = this.convertToJSON(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Invoices_invoice_terms_dd_single') {
      const title = 'Invoice Terms';
      const component = Invoices_invoice_terms_dd_singleComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Invoices_invoice_types_dd_single') {
      const title = 'Invoice Types';
      const component = Invoices_invoice_types_dd_singleComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Invoices_invoices_dd_single') {
      const title = 'Invoices dropdown';
      const component = Invoices_invoices_dd_singleComponent;
      const inParams:{ projectId: number, typeId: number, statusId: number[] } = { projectId: null, typeId: null, statusId: [] };
      if (!isNil(params.get('projectId'))) {
        const paramValueString = params.get('projectId');
        inParams.projectId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('typeId'))) {
        const paramValueString = params.get('typeId');
        inParams.typeId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('statusId'))) {
        const paramValueString = params.get('statusId');
        inParams.statusId = this.convertToJSON(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Invoices_invoicing_instructions_dd_single') {
      this.logger.log('Invoices', 'invoicing_instructions_dd_single');
      const title = 'invoicing_instructions_dd';
      const component = Invoices_invoicing_instructions_dd_singleComponent;
      const inParams:{ billingContractId?: number } = { billingContractId: null };
      if (!isNil(params.get('billingContractId'))) {
        const paramValueString = params.get('billingContractId');
        inParams.billingContractId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Invoices_invoicing_options_dd_single') {
      this.logger.log('Invoices', 'invoicing_options_dd_single');
      const title = 'invoicing_options_dd';
      const component = Invoices_invoicing_options_dd_singleComponent;
      const inParams:{ dimension?: string, exclude?: string[] } = { dimension: null, exclude: [] };
      if (!isNil(params.get('dimension'))) {
        const paramValueString = params.get('dimension');
        // TODO: date
        inParams.dimension = paramValueString;
              }
      if (!isNil(params.get('exclude'))) {
        const paramValueString = params.get('exclude');
        inParams.exclude = this.convertToJSON(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Invoices_invoicing_projects_dd_single') {
      this.logger.log('Invoices', 'invoicing_projects_dd_single');
      const title = 'invoicing_projects_dd';
      const component = Invoices_invoicing_projects_dd_singleComponent;
      const inParams:{ projectIds?: number[] } = { projectIds: [] };
      if (!isNil(params.get('projectIds'))) {
        const paramValueString = params.get('projectIds');
        inParams.projectIds = this.convertToJSON(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Invoices_location_storage_categories_dd_single') {
      const title = 'location_storage_categories_dd';
      const component = Invoices_location_storage_categories_dd_singleComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Invoices_measurement_units_dd_single') {
      const title = 'Measurement Units';
      const component = Invoices_measurement_units_dd_singleComponent;
      const inParams:{ id?: number[], typeId?: number[] } = { id: [], typeId: [] };
      if (!isNil(params.get('id'))) {
        const paramValueString = params.get('id');
        inParams.id = this.convertToJSON(paramValueString);
      }
      if (!isNil(params.get('typeId'))) {
        const paramValueString = params.get('typeId');
        inParams.typeId = this.convertToJSON(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Invoices_operation_codes_dd_single') {
      const title = 'Operation Codes';
      const component = Invoices_operation_codes_dd_singleComponent;
      const inParams:{ id?: number[], operationTypeId?: number[] } = { id: [], operationTypeId: [] };
      if (!isNil(params.get('id'))) {
        const paramValueString = params.get('id');
        inParams.id = this.convertToJSON(paramValueString);
      }
      if (!isNil(params.get('operationTypeId'))) {
        const paramValueString = params.get('operationTypeId');
        inParams.operationTypeId = this.convertToJSON(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Invoices_reasoncodes_dd_single') {
      const title = 'Reasoncodes dropdown';
      const component = Invoices_reasoncodes_dd_singleComponent;
      const inParams:{ parentId: number, parentEntity: string } = { parentId: null, parentEntity: null };
      if (!isNil(params.get('parentId'))) {
        const paramValueString = params.get('parentId');
        inParams.parentId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('parentEntity'))) {
        const paramValueString = params.get('parentEntity');
        // TODO: date
        inParams.parentEntity = paramValueString;
              }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Invoices_recurring_storage_billing_objects_dd_single') {
      const title = 'recurring_storage_billing_objects_dd';
      const component = Invoices_recurring_storage_billing_objects_dd_singleComponent;
      const inParams:{ ids?: number[] } = { ids: [] };
      if (!isNil(params.get('ids'))) {
        const paramValueString = params.get('ids');
        inParams.ids = this.convertToJSON(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Invoices_recurring_storage_schedule_types_dd_single') {
      const title = 'recurring_storage_schedule_types_dd';
      const component = Invoices_recurring_storage_schedule_types_dd_singleComponent;
      const inParams:{ ids?: number[], idsToExclude?: number[] } = { ids: [], idsToExclude: [] };
      if (!isNil(params.get('ids'))) {
        const paramValueString = params.get('ids');
        inParams.ids = this.convertToJSON(paramValueString);
      }
      if (!isNil(params.get('idsToExclude'))) {
        const paramValueString = params.get('idsToExclude');
        inParams.idsToExclude = this.convertToJSON(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Invoices_renewal_types_dd_single') {
      const title = 'renewal_types_dd';
      const component = Invoices_renewal_types_dd_singleComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Invoices_tags_dd_single') {
      const title = 'tags_dd';
      const component = Invoices_tags_dd_singleComponent;
      const inParams:{ entityTypeId?: number } = { entityTypeId: null };
      if (!isNil(params.get('entityTypeId'))) {
        const paramValueString = params.get('entityTypeId');
        inParams.entityTypeId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Invoices_tax_schedules_dd_single') {
      const title = 'tax_schedules_dd';
      const component = Invoices_tax_schedules_dd_singleComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Invoices_transaction_statuses_dd_single') {
      this.logger.log('Invoices', 'transaction_statuses_dd_single');
      const title = 'transaction_statuses_dd';
      const component = Invoices_transaction_statuses_dd_singleComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Invoices_warehouses_dd_single') {
      const title = 'Warehouses';
      const component = Invoices_warehouses_dd_singleComponent;
      const inParams:{ id?: number[] } = { id: [] };
      if (!isNil(params.get('id'))) {
        const paramValueString = params.get('id');
        inParams.id = this.convertToJSON(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Invoices_billing_accessorial_charges_templates_dd_multi') {
      const title = 'Billing Accessorial Charges Templates';
      const component = Invoices_billing_accessorial_charges_templates_dd_multiComponent;
      const inParams:{ id?: number[], enabled?: boolean } = { id: [], enabled: null };
      if (!isNil(params.get('id'))) {
        const paramValueString = params.get('id');
        inParams.id = this.convertToJSON(paramValueString);
      }
      if (!isNil(params.get('enabled'))) {
        const paramValueString = params.get('enabled');
        inParams.enabled = this.convertToBoolean(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Invoices_billing_aggregation_strategies_dd_multi') {
      const title = 'billing_aggregation_strategies_dd';
      const component = Invoices_billing_aggregation_strategies_dd_multiComponent;
      const inParams:{ id?: number[], name?: string } = { id: [], name: null };
      if (!isNil(params.get('id'))) {
        const paramValueString = params.get('id');
        inParams.id = this.convertToJSON(paramValueString);
      }
      if (!isNil(params.get('name'))) {
        const paramValueString = params.get('name');
        // TODO: date
        inParams.name = paramValueString;
              }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Invoices_billing_contract_line_date_range_multi') {
      const title = 'billing_contract_line_date_range';
      const component = Invoices_billing_contract_line_date_range_multiComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Invoices_billing_contracts_grid_days_dd_multi') {
      this.logger.log('Invoices', 'billing_contracts_grid_days_dd_multi');
      const title = 'Billing Contract Days Drop Down';
      const component = Invoices_billing_contracts_grid_days_dd_multiComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Invoices_billing_contracts_status_dd_multi') {
      const title = 'billing_contracts_status_dd';
      const component = Invoices_billing_contracts_status_dd_multiComponent;
      const inParams:{ statusIds?: number[] } = { statusIds: [] };
      if (!isNil(params.get('statusIds'))) {
        const paramValueString = params.get('statusIds');
        inParams.statusIds = this.convertToJSON(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Invoices_billing_days_remaining_dd_multi') {
      const title = 'billing_days_remaining_dd';
      const component = Invoices_billing_days_remaining_dd_multiComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Invoices_billing_frequency_dd_multi') {
      const title = 'billing_frequency_dd';
      const component = Invoices_billing_frequency_dd_multiComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Invoices_billing_grace_periods_dd_multi') {
      const title = 'billing_grace_periods_dd';
      const component = Invoices_billing_grace_periods_dd_multiComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Invoices_billing_initiating_date_dd_multi') {
      const title = 'billing_initiating_date_dd';
      const component = Invoices_billing_initiating_date_dd_multiComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Invoices_billing_methods_dd_multi') {
      const title = 'Billing Methods';
      const component = Invoices_billing_methods_dd_multiComponent;
      const inParams:{ idsToExlude?: number[], idsToInclude?: number[] } = { idsToExlude: [], idsToInclude: [] };
      if (!isNil(params.get('idsToExlude'))) {
        const paramValueString = params.get('idsToExlude');
        inParams.idsToExlude = this.convertToJSON(paramValueString);
      }
      if (!isNil(params.get('idsToInclude'))) {
        const paramValueString = params.get('idsToInclude');
        inParams.idsToInclude = this.convertToJSON(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Invoices_billing_objects_dd_multi') {
      const title = 'billing_objects_dd';
      const component = Invoices_billing_objects_dd_multiComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Invoices_billing_outbound_shipment_service_type_methods_dd_multi') {
      const title = 'billing_outbound_shipment_service_type_methods_dd';
      const component = Invoices_billing_outbound_shipment_service_type_methods_dd_multiComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Invoices_billing_recurring_grace_periods_dd_multi') {
      const title = 'billing_recurring_grace_periods_dd';
      const component = Invoices_billing_recurring_grace_periods_dd_multiComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Invoices_billing_schedule_types_dd_multi') {
      this.logger.log('Invoices', 'billing_schedule_types_dd_multi');
      const title = 'billing_schedule_types_dd';
      const component = Invoices_billing_schedule_types_dd_multiComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Invoices_billing_weekdays_dd_multi') {
      const title = 'billing_weekdays_dd';
      const component = Invoices_billing_weekdays_dd_multiComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Invoices_billingcodes_dd_multi') {
      const title = 'List of Billing Codes';
      const component = Invoices_billingcodes_dd_multiComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Invoices_carrier_service_types_dd_multi') {
      const title = 'carrier_service_types_dd';
      const component = Invoices_carrier_service_types_dd_multiComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Invoices_currencies_dd_multi') {
      const title = 'currencies_dd';
      const component = Invoices_currencies_dd_multiComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Invoices_diverted_projects_dd_multi') {
      this.logger.log('Invoices', 'diverted_projects_dd_multi');
      const title = 'Diverted Projects';
      const component = Invoices_diverted_projects_dd_multiComponent;
      const inParams:{ projectId: number } = { projectId: null };
      if (!isNil(params.get('projectId'))) {
        const paramValueString = params.get('projectId');
        inParams.projectId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Invoices_invoice_statuses_dd_multi') {
      const title = 'List of invoice statuses.';
      const component = Invoices_invoice_statuses_dd_multiComponent;
      const inParams:{ excludedStatusId?: number, includedStatusIds?: number[] } = { excludedStatusId: null, includedStatusIds: [] };
      if (!isNil(params.get('excludedStatusId'))) {
        const paramValueString = params.get('excludedStatusId');
        inParams.excludedStatusId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('includedStatusIds'))) {
        const paramValueString = params.get('includedStatusIds');
        inParams.includedStatusIds = this.convertToJSON(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Invoices_invoice_terms_dd_multi') {
      const title = 'Invoice Terms';
      const component = Invoices_invoice_terms_dd_multiComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Invoices_invoice_types_dd_multi') {
      const title = 'Invoice Types';
      const component = Invoices_invoice_types_dd_multiComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Invoices_invoices_dd_multi') {
      const title = 'Invoices dropdown';
      const component = Invoices_invoices_dd_multiComponent;
      const inParams:{ projectId: number, typeId: number, statusId: number[] } = { projectId: null, typeId: null, statusId: [] };
      if (!isNil(params.get('projectId'))) {
        const paramValueString = params.get('projectId');
        inParams.projectId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('typeId'))) {
        const paramValueString = params.get('typeId');
        inParams.typeId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('statusId'))) {
        const paramValueString = params.get('statusId');
        inParams.statusId = this.convertToJSON(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Invoices_invoicing_instructions_dd_multi') {
      this.logger.log('Invoices', 'invoicing_instructions_dd_multi');
      const title = 'invoicing_instructions_dd';
      const component = Invoices_invoicing_instructions_dd_multiComponent;
      const inParams:{ billingContractId?: number } = { billingContractId: null };
      if (!isNil(params.get('billingContractId'))) {
        const paramValueString = params.get('billingContractId');
        inParams.billingContractId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Invoices_invoicing_options_dd_multi') {
      this.logger.log('Invoices', 'invoicing_options_dd_multi');
      const title = 'invoicing_options_dd';
      const component = Invoices_invoicing_options_dd_multiComponent;
      const inParams:{ dimension?: string, exclude?: string[] } = { dimension: null, exclude: [] };
      if (!isNil(params.get('dimension'))) {
        const paramValueString = params.get('dimension');
        // TODO: date
        inParams.dimension = paramValueString;
              }
      if (!isNil(params.get('exclude'))) {
        const paramValueString = params.get('exclude');
        inParams.exclude = this.convertToJSON(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Invoices_invoicing_projects_dd_multi') {
      this.logger.log('Invoices', 'invoicing_projects_dd_multi');
      const title = 'invoicing_projects_dd';
      const component = Invoices_invoicing_projects_dd_multiComponent;
      const inParams:{ projectIds?: number[] } = { projectIds: [] };
      if (!isNil(params.get('projectIds'))) {
        const paramValueString = params.get('projectIds');
        inParams.projectIds = this.convertToJSON(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Invoices_location_storage_categories_dd_multi') {
      const title = 'location_storage_categories_dd';
      const component = Invoices_location_storage_categories_dd_multiComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Invoices_measurement_units_dd_multi') {
      const title = 'Measurement Units';
      const component = Invoices_measurement_units_dd_multiComponent;
      const inParams:{ id?: number[], typeId?: number[] } = { id: [], typeId: [] };
      if (!isNil(params.get('id'))) {
        const paramValueString = params.get('id');
        inParams.id = this.convertToJSON(paramValueString);
      }
      if (!isNil(params.get('typeId'))) {
        const paramValueString = params.get('typeId');
        inParams.typeId = this.convertToJSON(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Invoices_operation_codes_dd_multi') {
      const title = 'Operation Codes';
      const component = Invoices_operation_codes_dd_multiComponent;
      const inParams:{ id?: number[], operationTypeId?: number[] } = { id: [], operationTypeId: [] };
      if (!isNil(params.get('id'))) {
        const paramValueString = params.get('id');
        inParams.id = this.convertToJSON(paramValueString);
      }
      if (!isNil(params.get('operationTypeId'))) {
        const paramValueString = params.get('operationTypeId');
        inParams.operationTypeId = this.convertToJSON(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Invoices_reasoncodes_dd_multi') {
      const title = 'Reasoncodes dropdown';
      const component = Invoices_reasoncodes_dd_multiComponent;
      const inParams:{ parentId: number, parentEntity: string } = { parentId: null, parentEntity: null };
      if (!isNil(params.get('parentId'))) {
        const paramValueString = params.get('parentId');
        inParams.parentId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('parentEntity'))) {
        const paramValueString = params.get('parentEntity');
        // TODO: date
        inParams.parentEntity = paramValueString;
              }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Invoices_recurring_storage_billing_objects_dd_multi') {
      const title = 'recurring_storage_billing_objects_dd';
      const component = Invoices_recurring_storage_billing_objects_dd_multiComponent;
      const inParams:{ ids?: number[] } = { ids: [] };
      if (!isNil(params.get('ids'))) {
        const paramValueString = params.get('ids');
        inParams.ids = this.convertToJSON(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Invoices_recurring_storage_schedule_types_dd_multi') {
      const title = 'recurring_storage_schedule_types_dd';
      const component = Invoices_recurring_storage_schedule_types_dd_multiComponent;
      const inParams:{ ids?: number[], idsToExclude?: number[] } = { ids: [], idsToExclude: [] };
      if (!isNil(params.get('ids'))) {
        const paramValueString = params.get('ids');
        inParams.ids = this.convertToJSON(paramValueString);
      }
      if (!isNil(params.get('idsToExclude'))) {
        const paramValueString = params.get('idsToExclude');
        inParams.idsToExclude = this.convertToJSON(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Invoices_renewal_types_dd_multi') {
      const title = 'renewal_types_dd';
      const component = Invoices_renewal_types_dd_multiComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Invoices_tags_dd_multi') {
      const title = 'tags_dd';
      const component = Invoices_tags_dd_multiComponent;
      const inParams:{ entityTypeId?: number } = { entityTypeId: null };
      if (!isNil(params.get('entityTypeId'))) {
        const paramValueString = params.get('entityTypeId');
        inParams.entityTypeId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Invoices_tax_schedules_dd_multi') {
      const title = 'tax_schedules_dd';
      const component = Invoices_tax_schedules_dd_multiComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Invoices_transaction_statuses_dd_multi') {
      this.logger.log('Invoices', 'transaction_statuses_dd_multi');
      const title = 'transaction_statuses_dd';
      const component = Invoices_transaction_statuses_dd_multiComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Invoices_warehouses_dd_multi') {
      const title = 'Warehouses';
      const component = Invoices_warehouses_dd_multiComponent;
      const inParams:{ id?: number[] } = { id: [] };
      if (!isNil(params.get('id'))) {
        const paramValueString = params.get('id');
        inParams.id = this.convertToJSON(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Invoices_billing_contract_lines_widget') {
      const title = 'Line count';
      const component = Invoices_billing_contract_lines_widgetComponent;
      const inParams:{ contractId: number } = { contractId: null };
      if (!isNil(params.get('contractId'))) {
        const paramValueString = params.get('contractId');
        inParams.contractId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Invoices_billing_contract_total_widget') {
      const title = 'Total billed';
      const component = Invoices_billing_contract_total_widgetComponent;
      const inParams:{ contractId: number } = { contractId: null };
      if (!isNil(params.get('contractId'))) {
        const paramValueString = params.get('contractId');
        inParams.contractId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Invoices_billing_record_total_billed_by_current_month_widget') {
      const title = 'Billed this month';
      const component = Invoices_billing_record_total_billed_by_current_month_widgetComponent;
      const inParams:{ date: string, owner_id?: number, project_id?: number, warehouse_ids?: number[], material_ids?: number[] } = { date: null, owner_id: null, project_id: null, warehouse_ids: [], material_ids: [] };
      if (!isNil(params.get('date'))) {
        const paramValueString = params.get('date');
        // TODO: date
        inParams.date = paramValueString;
              }
      if (!isNil(params.get('owner_id'))) {
        const paramValueString = params.get('owner_id');
        inParams.owner_id = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('project_id'))) {
        const paramValueString = params.get('project_id');
        inParams.project_id = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('warehouse_ids'))) {
        const paramValueString = params.get('warehouse_ids');
        inParams.warehouse_ids = this.convertToJSON(paramValueString);
      }
      if (!isNil(params.get('material_ids'))) {
        const paramValueString = params.get('material_ids');
        inParams.material_ids = this.convertToJSON(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Invoices_billing_record_total_billed_by_date_widget') {
      const title = 'Billed today';
      const component = Invoices_billing_record_total_billed_by_date_widgetComponent;
      const inParams:{ date: string, ownerId?: number, projectId?: number, warehouseIds?: number[], materialIds?: number[] } = { date: null, ownerId: null, projectId: null, warehouseIds: [], materialIds: [] };
      if (!isNil(params.get('date'))) {
        const paramValueString = params.get('date');
        // TODO: date
        inParams.date = paramValueString;
              }
      if (!isNil(params.get('ownerId'))) {
        const paramValueString = params.get('ownerId');
        inParams.ownerId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('projectId'))) {
        const paramValueString = params.get('projectId');
        inParams.projectId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('warehouseIds'))) {
        const paramValueString = params.get('warehouseIds');
        inParams.warehouseIds = this.convertToJSON(paramValueString);
      }
      if (!isNil(params.get('materialIds'))) {
        const paramValueString = params.get('materialIds');
        inParams.materialIds = this.convertToJSON(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Invoices_billing_records_total_invoiced_by_date_widget') {
      const title = 'Invoiced today';
      const component = Invoices_billing_records_total_invoiced_by_date_widgetComponent;
      const inParams:{ date: string, ownerId?: number, projectId?: number, warehouseIds?: number[], materialIds?: number[] } = { date: null, ownerId: null, projectId: null, warehouseIds: [], materialIds: [] };
      if (!isNil(params.get('date'))) {
        const paramValueString = params.get('date');
        // TODO: date
        inParams.date = paramValueString;
              }
      if (!isNil(params.get('ownerId'))) {
        const paramValueString = params.get('ownerId');
        inParams.ownerId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('projectId'))) {
        const paramValueString = params.get('projectId');
        inParams.projectId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('warehouseIds'))) {
        const paramValueString = params.get('warehouseIds');
        inParams.warehouseIds = this.convertToJSON(paramValueString);
      }
      if (!isNil(params.get('materialIds'))) {
        const paramValueString = params.get('materialIds');
        inParams.materialIds = this.convertToJSON(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Invoices_invoice_total_billed_widget') {
      const title = 'Total billed';
      const component = Invoices_invoice_total_billed_widgetComponent;
      const inParams:{ invoiceId: number } = { invoiceId: null };
      if (!isNil(params.get('invoiceId'))) {
        const paramValueString = params.get('invoiceId');
        inParams.invoiceId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Invoices_invoice_total_billed_with_filters_widget') {
      const title = 'Total billed';
      const component = Invoices_invoice_total_billed_with_filters_widgetComponent;
      const inParams:{ ownerId?: number, projectId?: number, statusIds?: number[], createdFrom?: string, createdTo?: string } = { ownerId: null, projectId: null, statusIds: [], createdFrom: null, createdTo: null };
      if (!isNil(params.get('ownerId'))) {
        const paramValueString = params.get('ownerId');
        inParams.ownerId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('projectId'))) {
        const paramValueString = params.get('projectId');
        inParams.projectId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('statusIds'))) {
        const paramValueString = params.get('statusIds');
        inParams.statusIds = this.convertToJSON(paramValueString);
      }
      if (!isNil(params.get('createdFrom'))) {
        const paramValueString = params.get('createdFrom');
        // TODO: date
        inParams.createdFrom = paramValueString;
              }
      if (!isNil(params.get('createdTo'))) {
        const paramValueString = params.get('createdTo');
        // TODO: date
        inParams.createdTo = paramValueString;
              }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Invoices_invoice_total_units_widget') {
      const title = 'Total units';
      const component = Invoices_invoice_total_units_widgetComponent;
      const inParams:{ invoiceId: number } = { invoiceId: null };
      if (!isNil(params.get('invoiceId'))) {
        const paramValueString = params.get('invoiceId');
        inParams.invoiceId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Invoices_invoice_total_units_with_filters_widget') {
      const title = 'Total units';
      const component = Invoices_invoice_total_units_with_filters_widgetComponent;
      const inParams:{ ownerId?: number, projectId?: number, statusIds?: number[], createdFrom?: string, createdTo?: string } = { ownerId: null, projectId: null, statusIds: [], createdFrom: null, createdTo: null };
      if (!isNil(params.get('ownerId'))) {
        const paramValueString = params.get('ownerId');
        inParams.ownerId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('projectId'))) {
        const paramValueString = params.get('projectId');
        inParams.projectId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('statusIds'))) {
        const paramValueString = params.get('statusIds');
        inParams.statusIds = this.convertToJSON(paramValueString);
      }
      if (!isNil(params.get('createdFrom'))) {
        const paramValueString = params.get('createdFrom');
        // TODO: date
        inParams.createdFrom = paramValueString;
              }
      if (!isNil(params.get('createdTo'))) {
        const paramValueString = params.get('createdTo');
        // TODO: date
        inParams.createdTo = paramValueString;
              }

      return { title: title, component: component, inParams: inParams };
    }

    let result = null;
    result = this.Utilities.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    return result;
  }
}
