import { Component, OnInit, Input, SimpleChanges, OnChanges, EventEmitter, Output, ViewChild, Inject, OnDestroy, forwardRef } from '@angular/core';
import { FormGroup, FormControl, Validators} from '@angular/forms';

import { DatexFormControl } from './models/datex-form-control';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles 
} from './models/control';
import { GridHeaderModel, GridCellModel, GridRowModel, CellStyles, GridContainerStyle, HeaderStyles } from './models/grid'
import { FieldModel } from './models/field'
import { ToolModel } from './models/tool';
import { Styles, ControlContainerStyles } from './models/style';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { isEqual, isNil, set } from 'lodash-es';
import { GridComponent, ELoadingStatus } from './components/grid.component';
import { BaseComponent } from './components/base.component';
import { WorkBook, read as readExcelFile, writeFile as writeExcelFile, utils as excelUtils } from 'xlsx';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { app_ShellService, EModalSize, EToasterType, EToasterPosition } from './app.shell.service';
import { app_OperationService } from './app.operation.service';
import { app_DatasourceService } from './app.datasource.index';
import { app_FlowService } from './app.flow.index';
import { app_ReportService } from './app.report.index';
import { app_LocalizationService } from './app.localization.service';
import { Language } from './localization.service';
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './app.frontend.types'
import { $frontendTypes as $types} from './app.frontend.types' 



interface Iapp_custom_purchase_order_received_inventory_gridComponentEntity {
Id?: number, ActualPackagedAmount?: number, ActualPackagedPackId?: number, ActualTargetLicensePlateId?: number, CompletedDateTime?: string, GrossWeight?: number, LotId?: number, MaterialId?: number, Material?: { LookupCode?: string }, Lot?: { LastReceiveDate?: string, LookupCode?: string, ReceiveDate?: string, VendorLot?: { LookupCode?: string, Vat?: string } }, ActualTargetLicensePlate?: { LookupCode?: string, Location?: { Name?: string } }, ActualPackagedPack?: { ShortName?: string }, WeightUOM?: { Short_name?: string }, ShipmentLine?: { ExpectedPackagedAmount?: number, OrderLineNumber?: number, ExpectedPackaged?: { ShortName?: string } }, Order?: { LookupCode?: string, OwnerReference?: string, VendorReference?: string }}

interface Iapp_custom_purchase_order_received_inventory_gridComponentInParams {
  orderId: number, shipmentId: number}


class app_custom_purchase_order_received_inventory_gridComponentRowModel extends GridRowModel {
  grid: app_custom_purchase_order_received_inventory_gridComponent;
  entity: Iapp_custom_purchase_order_received_inventory_gridComponentEntity;



 // temporarily put required on all edit controls
  override formGroup = new FormGroup({
  });

  override cells = {
    linenumber: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    material: new GridCellModel(
      new CellStyles(['grid-table-cell-link'], null),
      new TextModel(null, null )
,
null
      ),
    total_expected: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    actual_packaged_amount: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    licenseplate: new GridCellModel(
      new CellStyles(['grid-table-cell-link'], null),
      new TextModel(null, null )
,
null
      ),
    location: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    Lot: new GridCellModel(
      new CellStyles(['grid-table-cell-link'], null),
      new TextModel(null, null )
,
null
      ),
    vendorlot: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
  }



  constructor(
    private utils: UtilsService,
private settings: SettingsValuesService,
private shell: app_ShellService,
private datasources: app_DatasourceService,
private flows: app_FlowService,
private reports: app_ReportService,
private localization: app_LocalizationService,
private operations: app_OperationService,
private logger: CleanupLoggerService,
) {
    super();
    
  }

  async $initializeExisting(grid: app_custom_purchase_order_received_inventory_gridComponent, entity: Iapp_custom_purchase_order_received_inventory_gridComponentEntity, propertyChangeCallback: (source: GridRowModel, property: string) => void = null) {
    this.$propertyChangeCallback = propertyChangeCallback;

    this.grid = grid;
    this.entity = entity;

    this.rowId = [this.entity.Id].join('-');

    await this.$dataLoaded();
  }

  async $initializeNew(grid: app_custom_purchase_order_received_inventory_gridComponent, entity?: Iapp_custom_purchase_order_received_inventory_gridComponentEntity, propertyChangeCallback: (source: GridRowModel, property: string) => void = null) {
    this.$propertyChangeCallback = propertyChangeCallback;

    this.grid = grid;
    this.isNew = true;

    if (entity) {
      this.entity = entity;
    }
    else {
      this.entity = {};
    }

    this.rowId = [this.entity.Id].join('-');
    this.$dataLoaded();
    // mark the whole form as dirty, since this is a new row 
    // and even if it is valid, it should have a changed state
    this.formGroup.markAsDirty();
  }

  private $unsubscribe$ = new Subject();
  override destroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }

  $init() {
  }

  async $dataLoad() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.utils;
    const $resultKey = this.entity.Id;
    const inParams = {
      $keys:[$resultKey],
      orderId:  $grid.inParams.orderId ,
      shipmentId:  $grid.inParams.shipmentId ,
      fullTextSearch:  $grid.fullTextSearch ,
    };
    const data = await this.datasources.app.custom_ds_received_inventory_grid.getByKeys(inParams);
    this.entity = data.result[0];
    await this.$dataLoaded();
  }

  async $dataLoaded() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.utils;
    (this.cells.linenumber.displayControl as TextModel).text = $row.entity.ShipmentLine?.OrderLineNumber?.toString();
    (this.cells.material.displayControl as TextModel).text = $row.entity.Material.LookupCode;
    (this.cells.licenseplate.displayControl as TextModel).text = $row.entity.ActualTargetLicensePlate?.LookupCode;
    (this.cells.location.displayControl as TextModel).text = $row.entity.ActualTargetLicensePlate?.Location?.Name;
    (this.cells.Lot.displayControl as TextModel).text = $row.entity.Lot.LookupCode;

    await this.on_row_data_loaded();
  }

  override async refresh() {
    if (this.grid.$hasMissingRequiredInParams) {
      return Promise.resolve(null);
    }
    await this.$dataLoad();
    // grid refresh skipSelf=true, skipParent=false, skipChildren=false  
    this.grid.$refreshEvent.emit();
  }

  override async save() {
    if (this.isNew) {
    } else {
    } 
  }

  override $cellClicked(cellId: string) {
    super.$cellClicked(cellId);
    switch(cellId) {
      case 'material' : {
        this.on_material_clicked();
        break;
      }
      case 'licenseplate' : {
        this.on_licenseplate_clicked();
        break;
      }
      case 'Lot' : {
        this.on_lot_clicked();
        break;
      }
    }
  }

  //#region private flows
  on_material_clicked(event = null) {
    return this.on_material_clickedInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_material_clickedInternal(
    $row: app_custom_purchase_order_received_inventory_gridComponentRowModel,
  $grid: app_custom_purchase_order_received_inventory_gridComponent, 
    $shell: app_ShellService,
    $datasources: app_DatasourceService,
    $flows: app_FlowService,
    $reports: app_ReportService,
    $settings: SettingsValuesService,
    $operations: app_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: app_LocalizationService,
    $event: any
  ) {
    this.logger.log('app', 'custom_purchase_order_received_inventory_grid.on_material_clicked');
  
  
  $shell.FootPrintManager.opensingle_material_hub({materialId: $row.entity.MaterialId});
  }
  on_lot_clicked(event = null) {
    return this.on_lot_clickedInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_lot_clickedInternal(
    $row: app_custom_purchase_order_received_inventory_gridComponentRowModel,
  $grid: app_custom_purchase_order_received_inventory_gridComponent, 
    $shell: app_ShellService,
    $datasources: app_DatasourceService,
    $flows: app_FlowService,
    $reports: app_ReportService,
    $settings: SettingsValuesService,
    $operations: app_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: app_LocalizationService,
    $event: any
  ) {
    this.logger.log('app', 'custom_purchase_order_received_inventory_grid.on_lot_clicked');
  
  
  $shell.FootPrintManager.opensingle_lot_hub({lotId: $row.entity.LotId});
  }
  on_row_data_loaded(event = null) {
    return this.on_row_data_loadedInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_row_data_loadedInternal(
    $row: app_custom_purchase_order_received_inventory_gridComponentRowModel,
  $grid: app_custom_purchase_order_received_inventory_gridComponent, 
    $shell: app_ShellService,
    $datasources: app_DatasourceService,
    $flows: app_FlowService,
    $reports: app_ReportService,
    $settings: SettingsValuesService,
    $operations: app_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: app_LocalizationService,
    $event: any
  ) {
    this.logger.log('app', 'custom_purchase_order_received_inventory_grid.on_row_data_loaded');
  
  
  
  if ($utils.isDefined($row.entity.Lot?.VendorLot?.Vat)) {
      $row.cells.vendorlot.displayControl.text = $row.entity.Lot?.VendorLot?.LookupCode + ' ' + $row.entity.Lot?.VendorLot?.Vat
  }
  else {
      $row.cells.vendorlot.displayControl.text = $row.entity.Lot?.VendorLot.LookupCode
  }
  
  
  // Set expected column
  $row.cells.total_expected.displayControl.text = $row.entity.ShipmentLine?.ExpectedPackagedAmount?.toString() + ' ' +  $row.entity.ShipmentLine?.ExpectedPackaged?.ShortName
  
  
  // Set actual column
  $row.cells.actual_packaged_amount.displayControl.text = $row.entity.ActualPackagedAmount?.toString() + ' ' +  $row.entity.ActualPackagedPack?.ShortName
  }
  on_licenseplate_clicked(event = null) {
    return this.on_licenseplate_clickedInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_licenseplate_clickedInternal(
    $row: app_custom_purchase_order_received_inventory_gridComponentRowModel,
  $grid: app_custom_purchase_order_received_inventory_gridComponent, 
    $shell: app_ShellService,
    $datasources: app_DatasourceService,
    $flows: app_FlowService,
    $reports: app_ReportService,
    $settings: SettingsValuesService,
    $operations: app_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: app_LocalizationService,
    $event: any
  ) {
    this.logger.log('app', 'custom_purchase_order_received_inventory_grid.on_licenseplate_clicked');
  
  
  $shell.FootPrintManager.opensingle_licenseplate_hub({licenseplateId: $row.entity.ActualTargetLicensePlateId});
  }
  //#endregion private flows

}


@Component({
  standalone: true,
  imports: [
    SharedModule,
  ],
  selector: 'app-custom_purchase_order_received_inventory_grid',
  templateUrl: './app.custom_purchase_order_received_inventory_grid.component.html'
})
export class app_custom_purchase_order_received_inventory_gridComponent extends BaseComponent implements OnInit, OnChanges, OnDestroy {
  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  @Output()
  $refreshEvent = new EventEmitter();
  //#endregion Outputs

  entities: Iapp_custom_purchase_order_received_inventory_gridComponentEntity[];

  pageSize = 10;
  pageSkip = 0;
  totalCount = 0;
  loadingStatus = ELoadingStatus.Loading;


// to stop add from flow
// canAdd = false; //hide add button, check on exitFromLastCell 
// bottomToolbar.addLine.hidden = true; //hide add button, no check on exitFromLastCell 
// bottomToolbar.addLine.control.readOnly = true; //disable add button, check on exitFromLastCell 
 
  
  containerStyles: GridContainerStyle = new GridContainerStyle('cellsWidth', ['compact','fit-content-table']);

  fullTextSearch: string;

  inParams: Iapp_custom_purchase_order_received_inventory_gridComponentInParams = { orderId: null, shipmentId: null };


  //#region Variables
  //#endregion
  //#region Events
  
  //#endregion

  headers = {
     linenumber: new GridHeaderModel(new HeaderStyles(null, null), 'Line #', false, false, null),       material: new GridHeaderModel(new HeaderStyles(null, null), 'Material', false, false, null),       total_expected: new GridHeaderModel(new HeaderStyles(null, null), 'Total Expected', false, false, null),       actual_packaged_amount: new GridHeaderModel(new HeaderStyles(null, null), 'Actual', false, false, null),       licenseplate: new GridHeaderModel(new HeaderStyles(null, null), 'Licenseplate', false, false, null),       location: new GridHeaderModel(new HeaderStyles(null, null), 'Current Location', false, false, null),       Lot: new GridHeaderModel(new HeaderStyles(null, null), 'Lot', false, false, null),       vendorlot: new GridHeaderModel(new HeaderStyles(null, null), 'Vendor Lot', false, false, null),  
  };

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  rows: app_custom_purchase_order_received_inventory_gridComponentRowModel[] = [];
  @ViewChild('$gridComponent', { read:  GridComponent}) $gridComponent: GridComponent;

  @Input('orderId') set $inParams_orderId(value: any) {
    this.inParams['orderId'] = value;
  }
  get $inParams_orderId(): any {
    return this.inParams['orderId'] ;
  }
  @Input('shipmentId') set $inParams_shipmentId(value: any) {
    this.inParams['shipmentId'] = value;
  }
  get $inParams_shipmentId(): any {
    return this.inParams['shipmentId'] ;
  }

  topToolbar = {
      unreceive: new ToolModel(new ButtonModel('unreceive', new ButtonStyles(null, null), false, 'Unreceive', 'ms-Icon ms-Icon--Annotation')
    )
  };

  bottomToolbar = {
  };



  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    private shell: app_ShellService,
    private datasources: app_DatasourceService,
    private flows: app_FlowService,
    private reports: app_ReportService,
    private localization: app_LocalizationService,
    private operations: app_OperationService,
    private logger: CleanupLoggerService,
    ) {
    super();
    this.title = 'Received Inventory';
    this.$subscribeFormControlValueChanges();
  }

  ngOnInit(): void {
    this.$checkRequiredInParams();
    if (!this.$hasMissingRequiredInParams) {
      this.$init();
    } else {
      this.$initEmpty();
    }
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$checkRequiredInParams();
      if(!this.$hasMissingRequiredInParams) {
        this.$init();
      } else {
        this.$initEmpty();
      }
    }
  }

  $missingRequiredInParams = [];
  get $hasMissingRequiredInParams(): boolean {
    return !!this.$missingRequiredInParams.length;
  }
  
  $checkRequiredInParams() {
    this.$missingRequiredInParams = [];
      if(isNil(this.inParams.orderId)) {
        this.$missingRequiredInParams.push('orderId');
      }
      if(isNil(this.inParams.shipmentId)) {
        this.$missingRequiredInParams.push('shipmentId');
      }
  }

  initialized = false;

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();

    this.clearRows();
  }

  async $init() {
    const $grid = this;
    const $utils = this.utils;


    this.initialized = true;

    this.pageSkip = 0;
    this.refresh(true, true, null);
  }

  protected override $initEmpty() {
    this.clearRows();
  }

  private $subscribeFormControlValueChanges() {

  }

  reload() {
    this.pageSkip = 0;
    this.refresh();
  }

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    if (this.$hasMissingRequiredInParams) {
      return Promise.resolve(null);
    }
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = this.$dataLoad();

    // children
    if (skipChildren === false) {
    }

    return result;
  }

  $rowPropertyChangeCallback (source: GridRowModel, property: string): void {
    if (property === 'selected') {
      this.$gridComponent.updateAllSelected();
    }
  }

  async $dataLoad() {
    this.loadingStatus = ELoadingStatus.Loading;
    const $grid = this;
    const $utils = this.utils;
    const inParams = {
      $top: this.pageSize,
      $skip: this.pageSkip,
      orderId:  $grid.inParams.orderId ,
      shipmentId:  $grid.inParams.shipmentId ,
      fullTextSearch:  $grid.fullTextSearch ,
    };
    try {
    const data = await this.datasources.app.custom_ds_received_inventory_grid.getList(inParams);
      this.entities = data.result;
      this.totalCount = data.totalCount;
      await this.$dataLoaded();
      if (this.totalCount === 0) {
        this.loadingStatus = ELoadingStatus.NoResults;
      } else {
        this.loadingStatus = ELoadingStatus.Loaded;
      }
    } catch(error) {
      console.error("Error loading data:", error);
      this.loadingStatus = ELoadingStatus.Error;
    }
  }

  async $dataLoaded() {
    const $grid = this;
    const $utils = this.utils;
    
    this.clearRows();

    if(this.entities) {
      let rowLoadPromises = [];
      for (let entity of this.entities) {
        const row = new app_custom_purchase_order_received_inventory_gridComponentRowModel(
          this.utils,
          this.settings,
          this.shell, 
          this.datasources,
          this.flows,
          this.reports,
          this.localization,
          this.operations,
          this.logger);
        rowLoadPromises.push( row.$initializeExisting(this, entity, this.$rowPropertyChangeCallback.bind(this)));
        this.rows.push(row);
      }
      await Promise.all(rowLoadPromises);
    }

  }

  clearRows() {
    if(this.rows && this.rows.length) {
      this.rows.forEach(r => r.destroy());
    }
    this.rows = [];
  }

  selectedRows = [];
  hasSelectedRows() {
    return this.selectedRows.length > 0;
  }

  $selectionChanged(selectedRows: any[]) {
    this.selectedRows = selectedRows;
    this.on_row_selected();
  }

  
  openImageViewer(imageSource: string) {
    this.shell.openImageViewerDialog(imageSource);
  }

  //#region private flows
  on_row_selected(event = null) {
    return this.on_row_selectedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_row_selectedInternal(
    $grid: app_custom_purchase_order_received_inventory_gridComponent,
  
    $shell: app_ShellService,
    $datasources: app_DatasourceService,
    $flows: app_FlowService,
    $reports: app_ReportService,
    $settings: SettingsValuesService,
    $operations: app_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: app_LocalizationService,
    $event: any
  ) {
    this.logger.log('app', 'custom_purchase_order_received_inventory_grid.on_row_selected');
  let order = (await $datasources.PurchaseOrders.ds_get_order_by_orderId.get({ orderId: $grid.inParams.orderId })).result
  if (order.OrderStatusId !== 4) {
      const hasOneSelectedRows = $grid.selectedRows.length === 1;
  
      $grid.topToolbar.unreceive.control.readOnly = !hasOneSelectedRows;
  
  } else {
      $grid.topToolbar.unreceive.control.readOnly = true;
  }
  
  
  }
  on_unreceive_clicked(event = null) {
    return this.on_unreceive_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_unreceive_clickedInternal(
    $grid: app_custom_purchase_order_received_inventory_gridComponent,
  
    $shell: app_ShellService,
    $datasources: app_DatasourceService,
    $flows: app_FlowService,
    $reports: app_ReportService,
    $settings: SettingsValuesService,
    $operations: app_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: app_LocalizationService,
    $event: any
  ) {
    this.logger.log('app', 'custom_purchase_order_received_inventory_grid.on_unreceive_clicked');
  
  
  const dialogResult = await $shell.FootPrintManager.openunreceiving_single_taskId_formDialog({
      licenseplateId: $grid.selectedRows[0].entity.ActualTargetLicensePlateId,
      lotId: $grid.selectedRows[0].entity.LotId,
      packagingId: $grid.selectedRows[0].entity.ActualPackagedPackId,
      packagedAmount:  $grid.selectedRows[0].entity.ActualPackagedAmount,
      taskId: $grid.selectedRows[0].entity.Id
  });
  const userConfirmed = dialogResult.confirm;
  
  
  
  if (userConfirmed) {
      await $grid.refresh();
  
  }
  }
  on_export_flow(event = null) {
    return this.on_export_flowInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_export_flowInternal(
    $grid: app_custom_purchase_order_received_inventory_gridComponent,
  
    $shell: app_ShellService,
    $datasources: app_DatasourceService,
    $flows: app_FlowService,
    $reports: app_ReportService,
    $settings: SettingsValuesService,
    $operations: app_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: app_LocalizationService,
    $event: any
  ) {
    this.logger.log('app', 'custom_purchase_order_received_inventory_grid.on_export_flow');
  //O.Arias - 09/10/2024
  
  var wb = $event;
  
  try {
  
      var response = (await $datasources.custom_ds_received_inventory_grid.get({
          orderId: $grid.inParams.orderId,
          shipmentId: $grid.inParams.shipmentId,
          fullTextSearch: $grid.fullTextSearch
      }))?.result;
  
  
      if ($utils.isDefined(response)) {
  
          //By License Plate
          let license_plates: number[] = response.map(r => r.ActualTargetLicensePlateId).filter((value, index, array) => array.indexOf(value) === index); //Distinct
  
          //By Lot
          let lots: number[] = response.map(r => r.LotId).filter((value, index, array) => array.indexOf(value) === index); //Distinct
  
          //By Packaging
          let packagings: number[] = response.map(r => r.ActualPackagedPackId).filter((value, index, array) => array.indexOf(value) === index); //Distinct
  
          //Distinct Order Lines
          let lines: number[] = response.map(r => r.ShipmentLine.OrderLineNumber).filter((value, index, array) => array.indexOf(value) === index); //Distinct
  
          //Distinct Shipment Lines
          let shipment_lines = response.map(r => r.ShipmentLine)
          let unique_list = [...new Set(shipment_lines.map(item => JSON.stringify(item)))];
          shipment_lines = unique_list.map(item => JSON.parse(item));
  
          let data: {
              "Receipt Date"?: string,
              "IB Order Lookup Code"?: string,
              "Material"?: string,
              "Total Expected"?: number,
              "Total Received"?: number,
              "License Plate"?: string,
              "Weight (by LP)"?: number,
              "Current Location"?: string,
              "Lot"?: string
          }[] = []
  
          let total_overall: number = 0;
          let weight_overall: number = 0;
  
          //Groupped by LP, LOT, PACKAGE
          for (let lp_id of license_plates) {
  
              for (let lot_id of lots) {
  
                  for (let pack_id of packagings) {
  
                      let details = response.filter(r =>
                          r.LotId === lot_id
                          && r.ActualPackagedPackId === pack_id
                          && r.ActualTargetLicensePlateId === lp_id
                      )
  
                      if ($utils.isDefined(details)) {
  
                          let task = details[0];
                          let total_received = details.map(d => d.ActualPackagedAmount).reduce((a, b) => a + b, 0);
                          let total_weight = details.map(d => d.GrossWeight).reduce((a, b) => a + b, 0);
  
                          let order_lines = details.map(r => r.ShipmentLine.OrderLineNumber).filter((value, index, array) => array.indexOf(value) === index); //Distinct
                          let expected: number = shipment_lines.filter(sl => order_lines.indexOf(sl.OrderLineNumber) >= 0).map(sl => sl.ExpectedPackagedAmount).reduce((a, b) => a + b, 0);
  
                          //For the summary line
                          total_overall = total_overall + total_received;
                          weight_overall = weight_overall + total_weight;
  
                          data.push({
                              "Receipt Date": date_format(task.Lot?.LastReceiveDate ?? task.Lot?.ReceiveDate ?? task.CompletedDateTime),
                              "IB Order Lookup Code": task.Order?.LookupCode,
                              "Material": task.Material?.LookupCode,
                              "Total Expected": expected,
                              "Total Received": total_received,
                              "License Plate": task.ActualTargetLicensePlate?.LookupCode,
                              "Weight (by LP)": total_weight,
                              "Current Location": task.ActualTargetLicensePlate?.Location?.Name,
                              "Lot": task.Lot?.LookupCode
                          })
                      }
                  }
              }
          }
  
          data.push({
              "Total Received": total_overall,
              "License Plate": "Total Received",
              "Weight (by LP)": weight_overall,
          })
  
          var ws = $utils.excel.json_to_sheet(data);
          $utils.excel.book_append_sheet(wb, ws, "ReceivedInventoryExport");
  
      }
  } catch (error) {
      const errorMessage = $utils.isDefined(error?.error?.error) ? error?.error?.error.message : error;
      throw new Error(errorMessage);
  }
  
  function date_format(date_string: string) {
  
      const format = `MM/DD/YYYY, LT`;
  
      if ($utils.isDefined(date_string)) {
          date_string = $utils.date.format(date_string, format);
      }
  
      return date_string;
  }
  }
  //#endregion private flows


  async $exportExcel() {
    const wb: WorkBook = excelUtils.book_new();

    await this.on_export_flow(wb);

    writeExcelFile(wb, 'app_custom_purchase_order_received_inventory_gridComponent_export.xlsx');
  }
 
  close() {
    this.$finish.emit();
  }
}
