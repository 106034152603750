import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class app_custom_ds_report_outbolsalesorders_get_line_detailsService {

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    ) { 
  }

  public async get(inParams: { taskId?: number }): 
  Promise<{ result: { Id?: number, ActualPackagedPackId?: number, ActualTargetLicensePlateId?: number, MaterialId?: number, ShipmentId?: number, ActualTargetLicensePlate?: { Id?: number, LookupCode?: string, ShipmentId?: number }, Material?: { Id?: number, Description?: string, LookupCode?: string, Name?: string }, ActualPackagedPack?: { Id?: number, DimensionUomId?: number, ShippingWeight?: number, Weight?: number, WeightUomId?: number, DimensionsUom?: { Id?: number, Short_name?: string }, WeightUom?: { Id?: number, Short_name?: string } }, IsPicked?: boolean } }> 
  {
    let url = `${environment.backendUrl}api/app/datasources/custom_ds_report_outbolsalesorders_get_line_details/get`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }

}
